import { IconVariants } from '~/shared/components/Icon';

import { CowsCopyKeyFragment } from '../../gql/fragments/cowsCopyKey.graphql';
import {
  DiseaseMappingsFormType,
  InseminationBullMappingsFormType,
  OperationMappingsFormType,
} from './hooks';
import { IdentifierMappingsFormType } from './hooks/useIdentifierMappingsForm';

/**
 * MappingType options
 */
export enum MappingType {
  equally = 'equally',
  create = 'create',
  ignore = 'ignore',
}

/**
 * ConformityMarkItem for MappingItem card with index signature
 */
export interface ConformityMarkItem extends Record<string, unknown> {
  id: MappingType;
  name: IconVariants;
}

/**
 * All add by copy key modal steps for configuring stepper modal
 */
export enum AddCowByKeyModalSteps {
  identifierMappings = 'identifierMappings',
  diseaseMappings = 'diseaseMappings',
  operationMappings = 'operationMappings',
  inseminationBullMappings = 'inseminationBullMappings',
}

/**
 * Form types by steps for configuring stepper modal
 */
export interface AddCowByKeyModalFormTypes {
  identifierMappings: IdentifierMappingsFormType;
  diseaseMappings: DiseaseMappingsFormType;
  operationMappings: OperationMappingsFormType;
  inseminationBullMappings: InseminationBullMappingsFormType;
}

/**
 * Completed payload for adding cows by copy key
 */
export type CowsCopyKeyPayload = { key: string } & IdentifierMappingsFormType &
  DiseaseMappingsFormType &
  OperationMappingsFormType &
  InseminationBullMappingsFormType;

/**
 * Global state for configuring stepper modal
 */
export type AddCowByKeyModalStepperState = CowsCopyKeyPayload | undefined;

/**
 * Props passed into each step for configuring stepper modal
 */
export interface AddCowByKeyModalZeroStepWrapperProps {
  submitActivateCowsByCopyKey: (payload: AddCowByKeyModalStepperState) => void;
  cowsCopyKey: CowsCopyKeyFragment | null;
}
