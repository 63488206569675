import React from 'react';

import { TableThemes } from '~/shared/components/Table';

import { CowsTable } from '~/entities/cows/components/CowsTable';
import { usePenGroupsDetailedQuery } from '~/entities/penGroups/gql/queries/penGroupsDetailed.graphql';

interface Props {
  /**
   * groupId to load pen group
   */
  groupId: string;
}

export const PenGroupNestedTable: React.FC<Props> = ({ groupId }) => {
  const { data: penGroupsData, loading: isPenGroupsLoading } =
    usePenGroupsDetailedQuery({
      variables: { ids: [groupId] },
    });
  const penGroup = penGroupsData?.penGroups.nodes.at(0);

  return (
    <CowsTable
      {...{
        items: penGroup?.cows,
        isLoading: isPenGroupsLoading,
        theme: TableThemes.nestedPrimary,
        noItemsMessage: 'В группе нет животных',
        withCustomScroll: false,
        withBorder: true,
      }}
    />
  );
};
