import { MilkParlorStatsDateKind } from '@graphql-types';
import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import dayjs from 'dayjs';
import R from 'ramda';

import { Table, TableColumnConfig } from '~/shared/components/Table';
import { TypographyVariants } from '~/shared/components/Typography';

import { DateFormats, formatDate } from '~/services/dateTime';

import { useMilkDataTabContext } from '~/widgets/cowCardEvents';

import { CowMilkParlorStatsRowFragment } from '../../gql/fragments/cowMilkParlorStatsRow.graphql';
import { useMilkParlorStatsQuery } from '../../gql/queries/milkParlorStats.graphql';
import styles from './index.module.scss';

const PARLOR_STATS_DATE_KINDS_TOOLTIPS_DICT: Record<
  MilkParlorStatsDateKind,
  string | undefined
> = {
  [MilkParlorStatsDateKind.Generic]: undefined,
  [MilkParlorStatsDateKind.NoData]: 'Данные перестали приходить',
  [MilkParlorStatsDateKind.Predicted]: 'Прогнозное значение',
};

export const Route = createFileRoute(
  '/$companyId/_layout/user/cows/$cowId/info/milk-parlors/'
)({
  wrapInSuspense: true,
  component: MilkParlorsTable,
});

function MilkParlorsTable() {
  const { cow: cowDetailed } = useMilkDataTabContext();

  const cowID = cowDetailed?.id;

  const { data: milkParlorStatsQueryData, loading: isLoading } =
    useMilkParlorStatsQuery({
      variables: {
        cowID: cowID!,
      },
      skip: !cowID,
    });

  const milkParlorStats = milkParlorStatsQueryData?.milkParlorStats ?? [];

  const columnConfigs: TableColumnConfig<
    CowMilkParlorStatsRowFragment,
    undefined
  >[] = [
    {
      title: 'Дата',
      key: 'name',
      itemField: 'name',
      headerClassName: styles.stickyCell,
      cellClassName: clsx(styles.stickyCell, 'background-gray-80'),
      cellTypographyProps: (item, itemIndex) => ({
        variant:
          itemIndex === milkParlorStats.length - 1
            ? TypographyVariants.heading5
            : undefined,
      }),
    },
    ...(milkParlorStats[0]?.dates ?? []).map<
      TableColumnConfig<CowMilkParlorStatsRowFragment, undefined>
    >(dateItem => ({
      title: dayjs(dateItem.date).isSame(dayjs(), 'day') ? (
        'Сегодня'
      ) : (
        <>
          <div>{formatDate(dateItem.date, DateFormats.dayAndMonth)}</div>
          <div className="text-muted">
            {formatDate(dateItem.date, DateFormats.onlyYear)}
          </div>
        </>
      ),
      headerClassName: 'py-4 px-8',
      key: dateItem.date,
      cellClassName: item => {
        const currentDateStat = item.dates.find(d => d.date === dateItem.date);

        return clsx(
          'text-right',
          currentDateStat?.kind === MilkParlorStatsDateKind.Predicted &&
            'background-accent-opaque-container-soft'
        );
      },
      cellTooltip: item => {
        const currentDateStat = item.dates.find(d => d.date === dateItem.date);
        if (!currentDateStat) return undefined;

        return PARLOR_STATS_DATE_KINDS_TOOLTIPS_DICT[currentDateStat.kind];
      },
      renderCellContent: item => {
        const currentDateStat = item.dates.find(d => d.date === dateItem.date);
        return currentDateStat?.weightKilograms;
      },
    })),
    {
      title: 'Ожидание',
      key: 'expectedWeightKilograms',
      itemField: 'expectedWeightKilograms',
      cellClassName: 'text-right',
    },
    {
      title: 'Отклонение',
      key: 'deviationWeightKilograms',
      itemField: 'deviationWeightKilograms',
      cellClassName: 'text-right',
    },
    {
      title: 'Среднее',
      key: 'averageWeightKilograms',
      itemField: 'averageWeightKilograms',
      cellClassName: 'text-right',
    },
  ];

  return (
    <div className="overflow-auto">
      <Table<CowMilkParlorStatsRowFragment, undefined, undefined>
        {...{
          className: 'min-w-full w-min',
          items: milkParlorStats,
          columnConfigs,
          getItemKey: R.prop('name'),
          isLoading,
          noItemsMessage: 'Нет данных о доильных залах',
        }}
      />
    </div>
  );
}
