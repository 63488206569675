import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { VeterinaryActivityFragmentDoc } from '../fragments/veterinaryActivity.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VeterinaryActivitiesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type VeterinaryActivitiesQuery = { __typename?: 'Query', veterinaryActivities: { __typename?: 'VeterinaryActivityInterfaceConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VeterinaryActivityInterfaceEdge', cursor: string, node: { __typename: 'Protocol', id: string, name: string, forYoung: boolean, forCows: boolean, processKind: Types.ProcessKindEnum, deactivatedAt?: string | null } | { __typename: 'Injection', id: string, name: string, isDefault: boolean } | { __typename: 'Disease', id: string, name: string, isDefault: boolean, shouldSpecifyLimb: boolean } | { __typename: 'Operation', id: string, name: string, isDefault: boolean } | { __typename: 'DefaultVeterinaryActivity', id: string, name: string, isDefault: boolean } }> } };


export const VeterinaryActivitiesDocument = gql`
    query veterinaryActivities($first: Int, $after: String, $search: String, $ids: [ID!]) {
  veterinaryActivities(first: $first, after: $after, search: $search, ids: $ids) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        ...VeterinaryActivity
      }
      cursor
    }
  }
}
    ${VeterinaryActivityFragmentDoc}`;

/**
 * __useVeterinaryActivitiesQuery__
 *
 * To run a query within a React component, call `useVeterinaryActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVeterinaryActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVeterinaryActivitiesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      search: // value for 'search'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useVeterinaryActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<VeterinaryActivitiesQuery, VeterinaryActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VeterinaryActivitiesQuery, VeterinaryActivitiesQueryVariables>(VeterinaryActivitiesDocument, options);
      }
export function useVeterinaryActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VeterinaryActivitiesQuery, VeterinaryActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VeterinaryActivitiesQuery, VeterinaryActivitiesQueryVariables>(VeterinaryActivitiesDocument, options);
        }
export function useVeterinaryActivitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VeterinaryActivitiesQuery, VeterinaryActivitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VeterinaryActivitiesQuery, VeterinaryActivitiesQueryVariables>(VeterinaryActivitiesDocument, options);
        }
export type VeterinaryActivitiesQueryHookResult = ReturnType<typeof useVeterinaryActivitiesQuery>;
export type VeterinaryActivitiesLazyQueryHookResult = ReturnType<typeof useVeterinaryActivitiesLazyQuery>;
export type VeterinaryActivitiesSuspenseQueryHookResult = ReturnType<typeof useVeterinaryActivitiesSuspenseQuery>;
export type VeterinaryActivitiesQueryResult = Apollo.QueryResult<VeterinaryActivitiesQuery, VeterinaryActivitiesQueryVariables>;