import { useEffect } from 'react';

import { createFileRoute, useRouter } from '@tanstack/react-router';

import { Loader } from '~/shared/components/Loader';

import { CHANGE_FARM_ERROR_CODE } from '~/services/gql';
import { makeBeforeLoadRedirect, RouterTabs } from '~/services/navigation';

import { useCowDetailedQuery } from '~/entities/cows/gql/queries/cowDetailed.graphql';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { CowPageHeader } from './components/CowPageHeader';
import styles from './index.module.scss';

export const Route = createFileRoute('/$companyId/_layout/user/cows/$cowId')({
  wrapInSuspense: true,
  component: CowPage,
  beforeLoad: makeBeforeLoadRedirect<{ cowId: string; companyId: string }>({
    from: '/$companyId/user/cows/$cowId',
    to: '/$companyId/user/cows/$cowId/info/events',
  }),
});

function CowPage() {
  const { cowId } = Route.useParams();

  const {
    data: cowDetailedData,
    loading: isCowLoading,
    error,
  } = useCowDetailedQuery({
    variables: {
      id: cowId ?? '',
    },
    skip: !cowId,
  });

  const { navigate, basepath } = useRouter();
  // Redirect to main page, if we try to access cow from a different farm
  useEffect(() => {
    if (
      error?.graphQLErrors.some(
        err => err.extensions?.code === CHANGE_FARM_ERROR_CODE
      )
    ) {
      navigate({ to: basepath, replace: true });
    }
  }, [error]);

  const cow = cowDetailedData?.cow;

  return (
    <>
      {isCowLoading && <Loader className={layoutStyles.fullHeightContainer} />}
      {!isCowLoading && cow && (
        <div className={layoutStyles.limitedContainer}>
          <CowPageHeader
            {...{
              cow,
            }}
          />
          <RouterTabs
            {...{
              tabs: [
                {
                  href: '/$companyId/user/cows/$cowId/info',
                  title: 'О животном',
                },
                {
                  href: '/$companyId/user/cows/$cowId/animal-fields',
                  title: 'Информация и показатели',
                },
              ],
            }}
          >
            {routeChildren => (
              <div className={styles.tabPanel}>{routeChildren}</div>
            )}
          </RouterTabs>
        </div>
      )}
    </>
  );
}
