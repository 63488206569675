import R from 'ramda';
import { match } from 'ts-pattern';

import { SkeletonPlaceholder } from '~/shared/components/Skeleton';
import {
  NO_NAME_MESSAGE,
  NO_VALUE_MESSAGE,
  NON_BREAKING_HYPHEN,
} from '~/shared/constants';
import { formatInt } from '~/shared/helpers/number';

import {
  DATE_RANGE_REGEXP,
  DAYS_OF_WEEK_DICT,
  formatDateRange,
} from '~/services/dateTime';
import { omitTypename } from '~/services/gql';

import { formatBull } from '~/entities/bulls';
import { formatEmployee } from '~/entities/employees';
import { formatInseminationScheme } from '~/entities/inseminationSchemes';
import { ReproductionCrReportSettingsFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrReportSettings.graphql';
import { ReproductionCrValueFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrValue.graphql';

import { REPRODUCTION_CR_REPORT_SETTINGS_FORM_SCHEMA } from '../constants';
import {
  ReproductionCrReportDataType,
  ReproductionCrReportSettingsFormType,
  ReproductionCrValueInputFormType,
} from '../types';

/**
 * Checks, if calculated CR report is empty
 */
export const isReproductionCrReportDataEmpty = (
  reportData:
    | ReproductionCrReportDataType
    | null
    | undefined
    | SkeletonPlaceholder
) => reportData?.__typename === 'ReproductionCrCalculatedReportEmpty';

/**
 * Checks, if calculated CR report contains simple CR groupings by entities
 */
export const isReproductionCrByEntityReportData = (
  reportData:
    | ReproductionCrReportDataType
    | null
    | undefined
    | SkeletonPlaceholder
) => reportData?.__typename === 'ReproductionCrCalculatedReport';

/**
 * Checks, if calculated CR report contains period comparison
 */
export const isReproductionCrComparisonReportData = (
  reportData:
    | ReproductionCrReportDataType
    | null
    | undefined
    | SkeletonPlaceholder
) => reportData?.__typename === 'ReproductionCrCalculatedComparisonReport';

/**
 * Checks, if calculated CR report contains cross CR
 */
export const isReproductionCrCrossTableReportData = (
  reportData:
    | ReproductionCrReportDataType
    | null
    | undefined
    | SkeletonPlaceholder
) => reportData?.__typename === 'ReproductionCrCalculatedCrossTableReport';

// Small helper for match expressions readability to avoid writing a lot of typeguards
const TN = <T extends ReproductionCrValueFragment['__typename']>(
  __typename: T
) => ({
  __typename,
});

/**
 * Formats CR value to display,
 * if true is passed as a second argument, creates array labels for chart axis
 */
export const formatReproductionCrValue = (
  value: ReproductionCrValueFragment | null,
  withArrayLabels = false
) =>
  match(value)
    .with(null, R.always(NO_VALUE_MESSAGE))
    .with(TN('Breed'), breed => breed.name || NO_NAME_MESSAGE)
    .with(TN('Bull'), bull =>
      formatBull(bull, { prefix: '', withNumberSign: false })
    )
    .with(TN('Employee'), employee =>
      formatEmployee(employee, { withFullName: false })
    )
    .with(TN('InseminationScheme'), formatInseminationScheme)
    .with(TN('ReproductionCrDateValue'), ({ since, till }) => {
      const formatted = formatDateRange(since, till);
      return withArrayLabels ? formatted.split(DATE_RANGE_REGEXP) : formatted;
    })
    .with(
      TN('ReproductionCrInseminationNumberValue'),
      ({ inseminationNumber }) => formatInt(inseminationNumber)
    )
    .with(
      TN('ReproductionCrIntervalBetweenInseminationsValue'),
      ({ periodStart, periodEnd }) =>
        // null period end means infinity by contract
        !periodEnd
          ? `Более ${formatInt(periodStart)} дней`
          : `${formatInt(periodStart)}${NON_BREAKING_HYPHEN}${formatInt(periodEnd)} дней`
    )
    .with(
      TN('ReproductionCrRowByAnimalCycleValue'),
      ({ periodStart, periodEnd }) =>
        periodStart === periodEnd
          ? formatInt(periodStart)
          : `${formatInt(periodStart)}${NON_BREAKING_HYPHEN}${formatInt(periodEnd)}`
    )
    .with(TN('ReproductionCrRowByDowsValue'), ({ dow }) =>
      dow ? DAYS_OF_WEEK_DICT[dow] : NO_VALUE_MESSAGE
    )
    .with(TN('SemenSupplier'), supplier => supplier.name || NO_NAME_MESSAGE)
    .exhaustive();

/**
 * Maps reproduction CR report grouping value into form representation
 */
export const mapReproductionCrValueToForm = (
  value: ReproductionCrValueFragment
) =>
  match(value)
    .with(TN('Breed'), ({ name }) => ({ bullBreed: name }))
    .with(TN('Bull'), ({ id }) => ({ bullID: id }))
    .with(TN('Employee'), ({ id }) => ({ employeeID: id }))
    .with(TN('InseminationScheme'), ({ id }) => ({ inseminationSchemeID: id }))
    .with(TN('ReproductionCrDateValue'), date => ({ date: omitTypename(date) }))
    .with(
      TN('ReproductionCrInseminationNumberValue'),
      ({ inseminationNumber }) => ({ inseminationNumber })
    )
    .with(
      TN('ReproductionCrIntervalBetweenInseminationsValue'),
      intervalBetweenInseminations => ({
        intervalBetweenInseminations: omitTypename(
          intervalBetweenInseminations
        ),
      })
    )
    .with(TN('ReproductionCrRowByAnimalCycleValue'), animalCycle => ({
      animalCycle: omitTypename(animalCycle),
    }))
    .with(TN('ReproductionCrRowByDowsValue'), ({ dow }) => ({ dows: dow }))
    .with(TN('SemenSupplier'), ({ name }) => ({ supplier: name }))
    .exhaustive() as ReproductionCrValueInputFormType;

/**
 * Maps reproduction CR report settings fragment into settings form fields representation
 */
export const mapReproductionCrReportSettingsToForm = (
  reportSettings: ReproductionCrReportSettingsFragment | SkeletonPlaceholder
): ReproductionCrReportSettingsFormType => ({
  ...REPRODUCTION_CR_REPORT_SETTINGS_FORM_SCHEMA.getDefault(),
  ...(R.pick(
    [
      'femaleAnimalKind',
      'xAxisMode',
      'xAxisStep',
      'yAxisMode',
      'yAxisShouldCompareToPreviousPeriod',
      'yAxisCompareToPreviousPeriodMode',
      'yAxisCompareToPreviousPeriodChartKind',
      'lactationGroupNumbers',
      'groupBy',
      'groupByChartKind',
    ],
    reportSettings ?? {}
  ) as ReproductionCrReportSettingsFormType),
  period: {
    interval: {
      since: reportSettings.since ?? '',
      till: reportSettings.till ?? '',
    },
    daysFromToday: null,
  },
  farmID: reportSettings.farm?.id ?? null,
  cowIDs: reportSettings.cows?.map(R.prop('id')) ?? null,
  xAxisValues:
    reportSettings.xAxisValues?.map(mapReproductionCrValueToForm) ?? null,
  groupByValues:
    reportSettings.groupByValues?.map(mapReproductionCrValueToForm) ?? null,
});
