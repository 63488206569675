import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { ProtocolFragment } from '../gql/fragments/protocol.graphql';
import {
  ProtocolsQuery,
  ProtocolsQueryVariables,
  useProtocolsQuery,
} from '../gql/queries/protocols.graphql';

/**
 * Hook for using protocols query with pagination
 */
export const useProtocolsPaginatedQuery = makeUsePaginatedQuery<
  ProtocolFragment,
  ProtocolsQuery,
  ProtocolsQueryVariables
>({
  useQuery: useProtocolsQuery,
  getItemsFromQueryData: data => data.protocols.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.protocols.pageInfo,
});
