import { ProcessKindEnum } from '@graphql-types';

/**
 * Dict with veterinary process kinds
 */
export const PROCESS_KINDS_DICT: Record<ProcessKindEnum, string> = {
  [ProcessKindEnum.Treatment]: 'Лечение',
  [ProcessKindEnum.Vaccination]: 'Вакцинация',
  [ProcessKindEnum.Synchronization]: 'Синхронизация',
  [ProcessKindEnum.Care]: 'Уход',
  [ProcessKindEnum.Common]: 'Общее',
};
