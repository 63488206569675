import styles from './index.module.scss';
import { TableColumnConfig } from './types';

/**
 * If number of table rows or columns is more than this and shouldHideLargeData === true,
 * overflown items are not rendered for better performance until the explicit user choice
 */
export const MIN_ENTRIES_COUNT_TO_HIDE = 100;

/**
 * Additional parameter to avoid hiding to little items, if the total number of items is less then
 * MIN_ENTRIES_COUNT_TO_HIDE + MIN_ENTRIES_THRESHOLD_TO_HIDE, hiding is not applied
 */
export const MIN_ENTRIES_THRESHOLD_TO_HIDE = 50;

/**
 * Column key for a special column with item action buttons
 */
export const ITEM_ACTIONS_COLUMN_KEY = 'itemActions';

/**
 * Filler column for using in fixed table with 100% width
 */
export const FILLER_COLUMN_CONFIG = {
  title: '',
  key: 'filler',
  renderCellContent: () => '',
  columnClassName: styles.fillerCell,
  headerTypographyProps: {
    withSkeleton: false,
  },
  cellTypographyProps: {
    withSkeleton: false,
  },
  width: '1000fr',
  isSplittedHeader: true,
} satisfies TableColumnConfig<any>;
