import React from 'react';

export const CheckCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.125 10C3.125 6.20304 6.20304 3.125 10 3.125C13.797 3.125 16.875 6.20304 16.875 10C16.875 13.797 13.797 16.875 10 16.875C6.20304 16.875 3.125 13.797 3.125 10ZM10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875ZM13.8012 7.9144C14.0301 7.656 14.0061 7.261 13.7477 7.03213C13.4893 6.80327 13.0943 6.82721 12.8655 7.0856L8.90227 11.5602L7.13233 9.58312C6.90209 9.32595 6.50697 9.30411 6.24979 9.53434C5.99261 9.76457 5.97077 10.1597 6.20101 10.4169L8.4391 12.9169C8.68474 13.1913 9.12845 13.1901 9.37263 12.9144L13.8012 7.9144Z"
      fill="currentColor"
    />
  </svg>
);
