import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { UserFragmentDoc } from '../../../../entities/users/gql/fragments/user.graphql';
import { CompanyShortFragmentDoc } from '../../../../entities/companies/gql/fragments/companyShort.graphql';
import { CowShortFragmentDoc } from '../../../../entities/cows/gql/fragments/cowShort.graphql';
import { OperationFragmentDoc } from '../../../../entities/operations/gql/fragments/operation.graphql';
import { DiseaseFragmentDoc } from '../../../../entities/diseases/gql/fragments/disease.graphql';
import { BullFragmentDoc } from '../../../../entities/bulls/gql/fragments/bull.graphql';
export type CowsCopyKeyFragment = { __typename: 'CowsCopyKey', key: string, reason: Types.CowsCopyReasonEnum, departDate: string, arriveDate?: string | null, activatedAt?: string | null, toCompanyInn?: string | null, toCompanyName?: string | null, creator: { __typename: 'User', id: string, email: any, firstName: string, lastName: string, middleName: string, companies: { __typename?: 'UserCompanyConnection', edges: Array<{ __typename?: 'UserCompanyEdge', node: { __typename: 'Company', id: string }, blueprintRoles?: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> | null }> }, integrator?: { __typename: 'Integrator', id: string, name: string } | null }, company: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean }, toCompany?: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean } | null, cows: Array<{ __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }>, operations: Array<{ __typename: 'Operation', id: string, name: string, isDefault: boolean }>, diseases: Array<{ __typename: 'Disease', id: string, name: string, isDefault: boolean, shouldSpecifyLimb: boolean }>, inseminationBulls: Array<{ __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null }> };

export const CowsCopyKeyFragmentDoc = gql`
    fragment CowsCopyKey on CowsCopyKey {
  __typename
  key
  reason
  departDate
  arriveDate
  activatedAt
  toCompanyInn
  toCompanyName
  creator {
    ...User
  }
  company {
    ...CompanyShort
  }
  toCompany {
    ...CompanyShort
  }
  cows {
    ...CowShort
  }
  operations {
    ...Operation
  }
  diseases {
    ...Disease
  }
  inseminationBulls {
    ...Bull
  }
}
    ${UserFragmentDoc}
${CompanyShortFragmentDoc}
${CowShortFragmentDoc}
${OperationFragmentDoc}
${DiseaseFragmentDoc}
${BullFragmentDoc}`;