import React, {
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { useApolloClient } from '@apollo/client';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useApiFeedback } from '~/~legacy/hooks/useApiFeedback';
import { useService } from '~/~legacy/hooks/useService';
import {
  BlueprintExecutionInfo,
  BlueprintExecutionService,
} from '~/~legacy/services/BlueprintExecutionService';
import { MaslovServices } from '~/~legacy/types/services';

import { Loader } from '~/shared/components/Loader';
import { RunType } from '~/shared/graphql';

import { formatDateForBackend } from '~/services/dateTime';
import { useConfirm } from '~/services/modals';
import { useArkaNavigation } from '~/services/navigation';

import { useBlueprintFilters } from '~/widgets/blueprintsList';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { BlueprintExecutionResults } from '../BlueprintExecutionResults';
import { BlueprintForms } from '../BlueprintForms';
import { BlueprintSpecialInput } from '../BlueprintSpecialInput';
import {
  BlueprintExecutionSteps,
  BlueprintExecutorActionTypes,
  blueprintExecutorReducer,
  defaultBlueprintExecutorState,
} from './reducer';

interface Props {
  blueprintId: string;

  cancel: () => void;
}

export const BlueprintExecutor: React.FC<Props> = ({ blueprintId, cancel }) => {
  const { navigate, urlCompanyId } = useArkaNavigation();
  const bpExecutionSvc = useService<BlueprintExecutionService>(
    MaslovServices.BlueprintExecutionService
  );

  const [state, dispatch] = useReducer(
    blueprintExecutorReducer,
    defaultBlueprintExecutorState
  );

  const { blueprintsDate } = useBlueprintFilters();

  const client = useApolloClient();

  const memoizedGetBpInfo = useMemo(() => {
    return bpExecutionSvc.getBlueprintExecutionInfo.bind(bpExecutionSvc);
  }, [bpExecutionSvc]);

  const {
    loading: infoLoading,
    errors: infoErrors,
    reload: loadExecutionInfo,
  } = useApiData(memoizedGetBpInfo);

  const memoizedExecuteBlueprint = useMemo(() => {
    return bpExecutionSvc.executeBlueprint.bind(bpExecutionSvc);
  }, [bpExecutionSvc]);

  const {
    loading: executing,
    errors: executionErrors,
    reload: execute,
  } = useApiData(memoizedExecuteBlueprint);

  const executionCallback = useCallback(
    async (executionInfo: BlueprintExecutionInfo) => {
      dispatch({
        type: BlueprintExecutorActionTypes.InputsSubmitted,
        data: executionInfo,
      });

      const executionResult = await execute({
        ...executionInfo,
        runOn: formatDateForBackend(blueprintsDate, true),
      });

      if (executionResult.success) {
        client.cache.evict({ fieldName: 'veterinaryActivities' });
        client.cache.evict({ fieldName: 'cowEvents' });
        client.cache.evict({ fieldName: 'cow' });
        client.cache.evict({ fieldName: 'cows' });
        client.cache.gc();

        dispatch({
          type: BlueprintExecutorActionTypes.Executed,
          data: executionResult.data,
        });
      }
    },
    [dispatch, blueprintsDate]
  );

  const { errorMessage, loader } = useApiFeedback(
    infoErrors,
    infoLoading || executing
  );

  const confirmBlueprintCancel = useConfirm();
  const closeCallback = useCallback(async () => {
    const isConfirmed = await confirmBlueprintCancel({
      title: 'Покинуть страницу?',
      message: 'Если вы покинете страницу, все внесенные данные будут удалены',
      submitButtonProps: {
        children: 'Покинуть страницу',
      },
      cancelButtonProps: {
        children: 'Остаться на странице',
      },
    });
    if (isConfirmed) {
      navigate({
        to:
          state.executionInfo?.runType === RunType.Schedule
            ? '/$companyId/user/production-calendar'
            : '/$companyId/user/incidents',
        params: {
          companyId: urlCompanyId,
        },
      });
    }
  }, [state.executionInfo]);

  useEffect(() => {
    const load = async () => {
      const result = await loadExecutionInfo(blueprintId);
      if (result.success) {
        dispatch({
          type: BlueprintExecutorActionTypes.InfoLoaded,
          data: result.data,
        });

        if (result.data?.inputs.length === 0 && !result.data.specialInput) {
          executionCallback(result.data);
        }
      }
    };
    load();
  }, [blueprintId, dispatch]);

  const componentsMap = {
    [BlueprintExecutionSteps.Loading]: () => <></>,
    [BlueprintExecutionSteps.Inputs]: () => {
      return state.executionInfo ? (
        <BlueprintForms
          errors={executionErrors}
          executionInfo={state.executionInfo}
          start={executionCallback}
          cancel={closeCallback}
        />
      ) : null;
    },
    [BlueprintExecutionSteps.SpecialInput]: () => {
      return state.executionInfo ? (
        <BlueprintSpecialInput
          errors={executionErrors}
          close={closeCallback}
          start={executionCallback}
          executionInfo={state.executionInfo}
        />
      ) : null;
    },
    [BlueprintExecutionSteps.Results]: () => {
      return state.executionInfo && state.executionResult ? (
        <BlueprintExecutionResults
          cancel={cancel}
          executionInfo={state.executionInfo}
          executionResult={state.executionResult}
        />
      ) : null;
    },
  } as Record<BlueprintExecutionSteps, () => ReactNode>;

  const getComponent = componentsMap[state.step];
  const componentToRender = useMemo(() => getComponent(), [getComponent]);

  return (
    <Suspense
      fallback={<Loader className={layoutStyles.fullHeightContainer} />}
    >
      {componentToRender}
      {errorMessage}
      {loader}
    </Suspense>
  );
};
