import React, { useEffect, useState } from 'react';

import { VitalityFilter } from '@graphql-types';
import { useLocation, useParams } from '@tanstack/react-router';
import clsx from 'clsx';

import { FunctionButton } from '~/shared/components/FunctionButton';
import { Icon, IconVariants } from '~/shared/components/Icon';
import { SelectVariants } from '~/shared/components/Select';
import { ACCORDION_COLLAPSE_DURATION_MS } from '~/shared/hooks/useAccordion';
import { OverflowAxes, useIsOverflow } from '~/shared/hooks/useIsOverflow';

import { useArkaNavigation } from '~/services/navigation';

import { CowAsyncSelect } from '~/entities/cows';

import { ARKA_NAVIGATION_MENU_CONFIG } from '../../../../constants';
import { NavigationMenuState } from '../../../../types';
import { Brand } from '../../../Brand';
import { CompanySelector } from '../CompanySelector';
import { NavigationMenuSection } from '../NavigationMenuSection';
import { NavigationMenuSelect } from '../NavigationMenuSelect';
import { UserProfileMenuItem } from '../UserProfileMenuItem';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If true, menu is rendered open above the main content (on mobile devices)
   */
  isMenuOpen: boolean;
  /**
   * If true, menu is rendered in collapsed mode with popups for selecting nested links
   */
  isMenuCollapsed: boolean;
  /**
   * Open states of the menu and its items
   */
  menuState: NavigationMenuState;
  /**
   * Action to  toggle menu
   */
  toggleMenu: () => void;
  /**
   * Called, when a menu item with children is toggled
   */
  onToggleItem: (key: string, newIsOpen?: boolean) => void;
}

const COW_SELECT_POPOVER_WIDTH_PX = 264;

const MAIN_NAVIGATION_MENU_ID = 'MainNavigationMenu';

export const NavigationMenu: React.FC<Props> = ({
  className,
  isMenuOpen,
  isMenuCollapsed,
  menuState,
  toggleMenu,
  onToggleItem,
}) => {
  const { ref: useIsOverflowRef, isOverflow } = useIsOverflow(
    OverflowAxes.y,
    ACCORDION_COLLAPSE_DURATION_MS
  );

  const { navigate, urlCompanyId } = useArkaNavigation();

  const { cowId } = useParams({ strict: false });

  const [cowSearch, setCowSearch] = useState('');
  // Clear select search based on switch url
  const { pathname } = useLocation();
  useEffect(() => {
    setCowSearch('');
  }, [pathname]);

  // Delay select component switch, when collapsing menu for smother transition
  const [shouldRenderCollapsedCowSelect, setShouldRenderCollapsedCowSelect] =
    useState(isMenuCollapsed);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldRenderCollapsedCowSelect(isMenuCollapsed);
    }, ACCORDION_COLLAPSE_DURATION_MS / 2);

    return () => {
      clearTimeout(timeout);
    };
  }, [isMenuCollapsed]);

  // Scroll active menu item into view, when open the page
  useEffect(() => {
    document
      .querySelector(`#${MAIN_NAVIGATION_MENU_ID} [aria-current="page"]`)
      ?.scrollIntoView();
  }, []);

  return (
    <nav id={MAIN_NAVIGATION_MENU_ID} className={clsx(styles.root, className)}>
      <div className="flex gap-12 py-4 pl-24">
        <FunctionButton
          {...{
            className: 'flex-none',
            iconVariant: IconVariants.menu,
            onPress: () => toggleMenu(),
            tooltip: isMenuCollapsed ? 'Развернуть меню' : 'Свернуть меню',
            tooltipProps: {
              placement: 'right',
            },
          }}
        />
        <Brand
          tabIndex={isMenuCollapsed ? -1 : undefined}
          className={styles.brand}
        />
      </div>
      <div className={styles.cowSearch}>
        <CowAsyncSelect
          {...{
            name: 'cowSearch',
            variant: shouldRenderCollapsedCowSelect
              ? SelectVariants.popupSearch
              : SelectVariants.full,
            placeholder: 'Поиск животного',
            rawValue: cowId,
            search: cowSearch,
            onSearchChange: setCowSearch,
            onValueChange: newValue => {
              if (!newValue) return;
              if (isMenuOpen) {
                toggleMenu();
              }
              navigate({
                to: '/$companyId/user/cows/$cowId',
                params: { cowId: newValue.id, companyId: urlCompanyId },
              });
            },
            popoverWidth: COW_SELECT_POPOVER_WIDTH_PX,
            popoverProps: {
              placement: isMenuCollapsed ? 'right-start' : 'bottom-start',
            },
            renderToggleButton: ({
              isOpen,
              closeMenu: closeSelectMenu,
              openMenu: openSelectMenu,
              getToggleButtonProps,
            }) => (
              <NavigationMenuSelect
                {...{
                  onPress: e => {
                    if (e.pointerType !== 'keyboard') return;

                    if (isOpen) {
                      closeSelectMenu();
                    } else {
                      openSelectMenu();
                    }
                  },
                  iconElement: (
                    <div
                      className={clsx('inline-flex', styles.showForCollapsed)}
                    >
                      <Icon variant={IconVariants.search} />
                    </div>
                  ),
                  ...getToggleButtonProps(),
                  isOpen,
                  tabIndex: 0,
                }}
              />
            ),
            queryOptions: {
              variables: {
                vitalityFilter: VitalityFilter.All,
              },
            },
          }}
        />
      </div>
      <div
        ref={useIsOverflowRef}
        className={clsx(
          styles.menuItems,
          isOverflow && styles.overflowMenuItems
        )}
      >
        {ARKA_NAVIGATION_MENU_CONFIG.map(menuItemConfig => (
          <NavigationMenuSection
            key={menuItemConfig.path}
            {...{
              isMenuCollapsed,
              isOpen: menuState.itemsIsOpenMap[menuItemConfig.path],
              config: menuItemConfig,
              onToggleItem,
              onLinkPress: () => {
                if (isMenuOpen) {
                  toggleMenu();
                }
              },
            }}
          />
        ))}
      </div>
      <div className={styles.bottomPanel}>
        <div className="px-16">
          <CompanySelector
            {...{
              isMenuCollapsed,
            }}
          />
        </div>
        <div className="px-16">
          <UserProfileMenuItem
            {...{
              isMenuCollapsed,
            }}
          />
        </div>
      </div>
    </nav>
  );
};
