import { ReactNode } from 'react';

import { BaseFieldProps } from '~/shared/types/controls';
import { ValueOf } from '~/shared/types/utility';

import { NUMBER_INPUT_VARIANTS } from './constants';

/**
 * Possible value of an input inside a form
 */
export type FormInputValueTypes = string | number | null | undefined;

/**
 * Possible variants of an input
 */
export enum InputVariants {
  text = 'text',
  search = 'search',
  int = 'int',
  float = 'float',
  percent = 'percent',
  money = 'money',
}

type NumberInputVariants = ValueOf<typeof NUMBER_INPUT_VARIANTS>;

type StringInputVariants = Exclude<InputVariants, NumberInputVariants>;

/**
 * Possible input display variants
 */
export enum InputThemes {
  unstyled = 'unstyled',
  basic = 'basic',
  light = 'light',
  dark = 'dark',
}

/**
 * Possible input sizes
 */
export enum InputSizes {
  small24 = 'small24',
  medium36 = 'medium36',
}

/**
 * Input addon can be a node or a render prop, depending on input props
 */
export type InputAddon = ReactNode | ((props: InputProps) => ReactNode);

export interface BaseInputProps {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * Input display theme
   */
  theme?: InputThemes;
  /**
   * Input size
   */
  size?: InputSizes;

  /**
   * Accepted symbols RegExp
   */
  accept?: RegExp;
  /**
   * Called to format the passed outer raw value into the inner displayed format
   * The only transform made by default is conversion to string for easier types
   */
  formatRawToDisplayedValue?: (str: string, props: InputProps) => string;
  /**
   * Prop for mask library to replace some symbols after caret position calculation
   */
  replace?: (str: string) => string;

  /**
   * Additional props for an inner input element
   */
  htmlInputProps?: React.ComponentProps<'input'>;
  /**
   * Element (usually, icon), that is displayed before input
   */
  addonBefore?: InputAddon;
  /**
   * Element (usually, icon), that is displayed after input
   */
  addonAfter?: InputAddon;
  /**
   * If true, browser autocomplete is used on the input, false by default,
   * cause some field names may confuse browser for wrong autocompletions
   */
  withAutoComplete?: boolean;
  /**
   * If true, the input is using a mask to format its value, default - true
   */
  withFormat?: boolean;
  /**
   * If true, the input feedback will be placed at the right
   */
  withRightFeedback?: boolean;
}

interface TypeDependantInputProps<Value> {
  /**
   * Called to format the inner value of the input into the raw outer format
   */
  formatDisplayedToRawValue?: (str: string, props: InputProps) => Value;
  /**
   * Called with current input value, when enter is pressed inside input
   */
  onEnter?: (value: Value, e: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface NumberInputProps
  extends BaseInputProps,
    BaseFieldProps<number | null>,
    TypeDependantInputProps<number | null> {
  /**
   * Variant of an input defines its value type, formatting and some display details
   */
  variant: NumberInputVariants;
  /**
   * Maximum allowed fraction digits to type for float number inputs
   */
  maximumFractionDigits?: number;
}

interface StringInputProps
  extends BaseInputProps,
    BaseFieldProps<string>,
    TypeDependantInputProps<string> {
  /**
   * Variant of an input defines its value type, formatting and some display details
   */
  variant?: StringInputVariants;
  /**
   * Maximum allowed fraction digits to type for float number inputs
   */
  maximumFractionDigits?: undefined;
}

/**
 * Props for an input
 */
export type InputProps = NumberInputProps | StringInputProps;
