import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { DiseaseFragment } from '../gql/fragments/disease.graphql';
import {
  DiseasesQuery,
  DiseasesQueryVariables,
  useDiseasesQuery,
} from '../gql/queries/diseases.graphql';

/**
 * Hook for using diseases query with pagination
 */
export const useDiseasesPaginatedQuery = makeUsePaginatedQuery<
  DiseaseFragment,
  DiseasesQuery,
  DiseasesQueryVariables
>({
  useQuery: useDiseasesQuery,
  getItemsFromQueryData: data => data.diseases.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.diseases.pageInfo,
});
