import React from 'react';

import clsx from 'clsx';

import {
  SkeletonPlaceholder,
  TextSkeletonSizes,
} from '~/shared/components/Skeleton';
import { Table, TableColumnConfig } from '~/shared/components/Table';
import { formatInt } from '~/shared/helpers/number';

import { formatPenGroup } from '~/entities/penGroups';

import { MilkingParlorMilkingMistakesReportGroupedRowEntryFragment } from '../../gql/fragments/milkingParlorMilkingMistakesReportGroupedRowEntry.graphql';
import { MilkingParlorMilkingMistakesReportRowEntryFragment } from '../../gql/fragments/milkingParlorMilkingMistakesReportRowEntry.graphql';

type TableRow =
  | MilkingParlorMilkingMistakesReportRowEntryFragment
  | MilkingParlorMilkingMistakesReportGroupedRowEntryFragment
  | SkeletonPlaceholder;

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Milking report rows to display
   */
  milkingParlorMilkingMistakesReportRows: TableRow[];
}

const isGroupedReportRow = (
  row: TableRow
): row is MilkingParlorMilkingMistakesReportGroupedRowEntryFragment =>
  row.__typename === 'MilkingParlorMilkingMistakesReportGroupedRowEntry';

const PEN_GROUP_COLUMN_WIDTH_PX = 156;
const OTHER_COLUMN_WIDTH_PX = 84;

const BASE_REPORT_COLUMN_PROPS = {
  columnClassName: 'text-right',
  cellTypographyProps: {
    skeletonProps: {
      size: TextSkeletonSizes.small,
    },
  },
  width: OTHER_COLUMN_WIDTH_PX,
};

export const MilkingMilkingMistakesReportTable: React.FC<Props> = ({
  className,
  milkingParlorMilkingMistakesReportRows,
}) => {
  const columnConfigs: TableColumnConfig<TableRow>[] = [
    {
      key: 'penGroup',
      title: 'Группа',
      renderCellContent: row => {
        if (isGroupedReportRow(row)) {
          return 'Итого';
        }
        return formatPenGroup(row.penGroup);
      },
      width: PEN_GROUP_COLUMN_WIDTH_PX,
    },
    {
      key: 'reattachCount',
      title: 'Переподкл. коровы',
      renderCellContent: row => formatInt(row.reattachCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'noMilkCount',
      title: 'Нет надоя',
      renderCellContent: row => formatInt(row.noMilkCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'enteredTwiceCount',
      title: 'Доилась дважды',
      renderCellContent: row => formatInt(row.enteredTwiceCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'noLetdownCount',
      title: 'Падение потока в первые 2 мин.',
      renderCellContent: row => formatInt(row.noLetdownCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'earlyFalloffCount',
      title: 'Спадение аппарата',
      renderCellContent: row => formatInt(row.earlyFalloffCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'lateRehangCount',
      title: 'Позднее переподкл.',
      renderCellContent: row => formatInt(row.lateRehangCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'manualDetachCount',
      title: 'Ручное откл.',
      renderCellContent: row => formatInt(row.manualDetachCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'manualModeCount',
      title: 'Доение в ручном режиме',
      renderCellContent: row => formatInt(row.manualModeCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'notIdentifiedCount',
      title: 'Неидент. коров',
      renderCellContent: row => formatInt(row.notIdentifiedCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'dryCount',
      title: 'Сухост.',
      renderCellContent: row => formatInt(row.dryCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'totalCount',
      title: 'Всего ошибок',
      renderCellContent: row => formatInt(row.totalCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
  ];

  return (
    <Table<TableRow>
      {...{
        className: clsx(className, 'min-w-full w-min'),
        getItemKey: (item, index) =>
          isGroupedReportRow(item) ? 'total' : (item.penGroup?.id ?? index),
        items: milkingParlorMilkingMistakesReportRows,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
      }}
    />
  );
};
