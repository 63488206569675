import { makeReadFragment, makeUpdateFragment } from '~/services/gql';

import {
  CowsCopyKeyFragment,
  CowsCopyKeyFragmentDoc,
} from './gql/fragments/cowsCopyKey.graphql';

/**
 * Reads cows copy key fragment from cache by id
 */
export const readCowsCopyKeyFragment = makeReadFragment<CowsCopyKeyFragment>({
  typeName: 'CowsCopyKey',
  fragment: CowsCopyKeyFragmentDoc,
  getIdentifyFields: key => ({ key }),
});

/**
 * Update CowsCopyKeyFragment in the cache
 */
export const updateCowsCopyKeyFragment =
  makeUpdateFragment<CowsCopyKeyFragment>({
    typeName: 'CowsCopyKey',
    fragment: CowsCopyKeyFragmentDoc,
    getIdentifyFields: key => ({ key }),
  });
