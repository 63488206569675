import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type DiseaseFragment = { __typename: 'Disease', id: string, name: string, isDefault: boolean, shouldSpecifyLimb: boolean };

export const DiseaseFragmentDoc = gql`
    fragment Disease on Disease {
  __typename
  id
  name
  isDefault
  shouldSpecifyLimb
}
    `;