import { useEffect } from 'react';

import { createFileRoute, useLocation } from '@tanstack/react-router';
import clsx from 'clsx';

import { ContextMenuButton } from '~/shared/components/ContextMenuButton';
import { IconVariants } from '~/shared/components/Icon';
import { Input, InputSizes, InputVariants } from '~/shared/components/Input';
import { TabsVariants } from '~/shared/components/Tabs';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';

import {
  makeBeforeLoadRedirect,
  RouterTabs,
  SearchableSearchParams,
  WithSearchParamsValidation,
} from '~/services/navigation';

import { PageHeader } from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

export const Route = createFileRoute('/$companyId/_layout/user/events/all')({
  wrapInSuspense: true,
  component: VeterinaryActivitiesPage,
  beforeLoad: makeBeforeLoadRedirect<{ companyId: string }>({
    from: '/$companyId/user/events/all',
    to: '/$companyId/user/events/all/diseases',
  }),
});

function VeterinaryActivitiesPage() {
  const { search: debouncedSearch, setSearch: setDebouncedSearch } =
    useSearchParamsState<WithSearchParamsValidation<SearchableSearchParams>>();
  const { search, setSearch } = useDebouncedSearch({
    defaultSearch: debouncedSearch,
    onDebouncedSearchChange: setDebouncedSearch,
  });

  // Reset search after url change
  const { pathname } = useLocation();
  useEffect(() => {
    setSearch('');
  }, [pathname]);

  return (
    <>
      <PageHeader
        {...{
          className: layoutStyles.limitedContainer,
          title: 'Ветеринарные активности',
          rightContent: (
            <ContextMenuButton
              {...{
                isFunctionButton: false,
                buttonProps: {
                  children: 'Добавить активность',
                  iconVariant: IconVariants.plus,
                  rightIconVariant: IconVariants.chevronDown,
                },
                items: [
                  {
                    onPress: () => {},
                    content: 'Действие',
                  },
                  {
                    onPress: () => {},
                    content: 'Инъекция',
                  },
                  {
                    onPress: () => {},
                    content: 'Протокол',
                  },
                  {
                    onPress: () => {},
                    content: 'Заболевание',
                  },
                ],
              }}
            />
          ),
        }}
      />

      <div
        className={clsx(
          layoutStyles.limitedContainer,
          panelStyles.largePanel,
          'p-24'
        )}
      >
        <RouterTabs
          {...{
            className: layoutStyles.stickyTableTabs,
            variant: TabsVariants.table,
            tabs: [
              {
                href: '/$companyId/user/events/all/diseases',
                title: 'Заболевание',
              },
              {
                href: '/$companyId/user/events/all/protocols',
                title: 'Протокол',
              },
              {
                href: '/$companyId/user/events/all/injections',
                title: 'Инъекция',
              },
              {
                href: '/$companyId/user/events/all/operations',
                title: 'Действие',
              },
              {
                href: '/$companyId/user/events/all/common',
                title: 'Общее',
              },
            ],
            rightContent: (
              <Input
                {...{
                  name: 'search',
                  placeholder: 'Поиск по названию',
                  value: search,
                  onValueChange: setSearch,
                  className: 'default-control min-control',
                  variant: InputVariants.search,
                  size: InputSizes.small24,
                }}
              />
            ),
          }}
        />
      </div>
    </>
  );
}
