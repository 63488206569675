import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type InjectionFragment = { __typename: 'Injection', id: string, name: string, isDefault: boolean };

export const InjectionFragmentDoc = gql`
    fragment Injection on Injection {
  __typename
  id
  name
  isDefault
}
    `;