import { InseminationScheme } from '@graphql-types';

import { NO_NAME_MESSAGE } from '~/shared/constants';

import { makeUpdateFragment } from '~/services/gql';

import {
  InseminationSchemeFragment,
  InseminationSchemeFragmentDoc,
} from './gql/fragments/inseminationScheme.graphql';

/**
 * Update InseminationSchemeFragment in the cache
 */
export const updateInseminationSchemeFragment =
  makeUpdateFragment<InseminationSchemeFragment>({
    typeName: 'InseminationScheme',
    fragment: InseminationSchemeFragmentDoc,
  });

/**
 * Returns insemination scheme formatted for rendering
 */
export const formatInseminationScheme = (
  scheme: Pick<InseminationScheme, 'name'>
) => scheme.name || NO_NAME_MESSAGE;
