import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { DiseaseFragmentDoc } from '../../gql/fragments/disease.graphql';
import { useDiseasesQuery } from '../../gql/queries/diseases.graphql';

/**
 * Select hook for a disease
 */
export const useDiseaseSelect = makeUseAsyncSelect({
  typeName: 'Disease',
  fragment: DiseaseFragmentDoc,
  useQuery: useDiseasesQuery,
  getItemsFromQueryData: data => data.diseases.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.diseases.pageInfo,

  selectProps: {
    placeholder: 'Выберите болезнь',
    getItemValue: item => item.id,
    getItemText: item => item?.name ?? '',
  },
});

/**
 * Select for a disease
 */
export const DiseaseAsyncSelect =
  makeAsyncSelectFormComponent(useDiseaseSelect);
