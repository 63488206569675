import React from 'react';

import clsx from 'clsx';
import { match } from 'ts-pattern';

import { mergeProps } from '~/shared/helpers/mergeProps';
import { useCustomScrollWrapper } from '~/shared/hooks/useCustomScrollWrapper';

import { Tab } from './components/Tab';
import styles from './index.module.scss';
import { TabsProps, TabsVariants } from './types';

export const Tabs: React.FC<TabsProps> = ({
  className,
  variant = TabsVariants.line,
  children,
  tabs,
  rightContent,
}) => {
  const isLineTabs = variant === TabsVariants.line;

  const { useScrollMeasureRef, renderCustomScrollWrapper } =
    useCustomScrollWrapper({
      shouldHideScrollBarsForNoOverflow: isLineTabs,
    });

  let tabsElement = (
    <div className={clsx(styles.root, styles[variant])}>
      {renderCustomScrollWrapper({
        className: 'overflow-hidden',
        scrollBarKey: tabs.length,
        isOnPage: isLineTabs,
        children: (
          <div ref={useScrollMeasureRef} className={styles.tabsScrollContainer}>
            {tabs.map(tabConfig => {
              return (
                <Tab
                  key={tabConfig.href}
                  {...{
                    variant,
                    tabConfig,
                  }}
                />
              );
            })}
          </div>
        ),
      })}

      {!!rightContent &&
        React.cloneElement(
          rightContent,
          mergeProps(rightContent.props, {
            className: 'ml-a',
          })
        )}
    </div>
  );

  tabsElement = match(variant)
    .with(TabsVariants.line, () => (
      <div className={className} data-sticky-tabs-variant={variant}>
        {tabsElement}
      </div>
    ))
    .with(TabsVariants.table, () => (
      <div
        className={clsx(styles.tableTabsWrapper, className)}
        data-sticky-tabs-variant={variant}
      >
        <div className={styles.tableTabsContainer}>{tabsElement}</div>
      </div>
    ))
    .exhaustive();

  return (
    <>
      {tabsElement}
      {children}
    </>
  );
};

export * from './types';
