import React, { ReactNode } from 'react';

import { useFocusRing } from '@react-aria/focus';
import { PressProps, usePress } from '@react-aria/interactions';
import clsx from 'clsx';

import { Icon, IconProps, IconVariants } from '~/shared/components/Icon';
import {
  TextSkeletonSizes,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Tooltip, TooltipProps } from '~/shared/components/Tooltip';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps } from '~/shared/helpers/mergeProps';

import { SizeVariants } from '~/styles/__generated__/token-variants';

import styles from './index.module.scss';

/**
 * Possible function button variants
 */
export enum FunctionButtonVariants {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  success = 'success',
  failure = 'failure',
}

/**
 * Possible function button sizes
 */
export enum FunctionButtonSizes {
  large24 = 'large24',
  medium20 = 'medium20',
}

export interface FunctionButtonProps
  extends Omit<React.ComponentProps<'button'>, 'disabled'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Button display variant
   */
  variant?: FunctionButtonVariants;
  /**
   * Button size
   */
  size?: FunctionButtonSizes;
  /**
   * If true, doesn't render a button tag, only div with role="button" aria attribute.
   * This is useful for placing nested interactive content,
   * like a button inside a link, which is forbidden by spec
   */
  withOnlyAria?: boolean;
  /**
   * If true, dark theme is used
   */
  isDark?: boolean;
  /**
   * Called, when the button is pressed with mouse or keyboard
   */
  onPress?: PressProps['onPress'];
  /**
   * If true, enables button style in press mode
   */
  isPressed?: boolean;
  /**
   * If true, click events won't fire and pointer cursor won't be used
   */
  isDisabled?: boolean;
  /**
   * Icon variant to display
   */
  iconVariant?: IconVariants;
  /**
   * If true, icon is rendered on the right side of the label
   */
  isWithRightIcon?: boolean;
  /**
   * Additional props for icon
   */
  iconProps?: Partial<Omit<IconProps, 'ref' | 'tooltip' | 'tooltipProps'>>;
  /**
   * Tooltip for button
   */
  tooltip?: ReactNode;
  /**
   * Additional props for the tooltip
   */
  tooltipProps?: Partial<TooltipProps>;
}

export const FunctionButton = React.forwardRef<
  HTMLButtonElement,
  FunctionButtonProps
>(
  (
    {
      className,
      variant = FunctionButtonVariants.tertiary,
      size = FunctionButtonSizes.large24,
      withOnlyAria = false,
      isDark = false,
      onPress,

      isDisabled = false,
      isPressed: isPressedProp,

      iconVariant,
      isWithRightIcon = false,
      iconProps,

      tooltip,
      tooltipProps,

      children,

      ...other
    }: FunctionButtonProps,
    ref
  ) => {
    const isLarge = size === FunctionButtonSizes.large24;

    const { renderWithoutSkeleton } = useSkeletonContext();

    const { pressProps, isPressed } = usePress({
      isDisabled,
      onPress,
      isPressed: isPressedProp,
    });

    // :focus-visible is not working with usePress correctly, so we use react-aria solution
    const { isFocusVisible, focusProps } = useFocusRing();

    const iconElement =
      iconVariant &&
      renderWithoutSkeleton(
        <Icon
          size={isLarge ? SizeVariants.size24 : SizeVariants.size20}
          variant={iconVariant}
          {...iconProps}
        />
      );

    // Assertion for ref types to pass checking to avoid writing complex generics
    const ComponentToRender = (withOnlyAria ? 'div' : 'button') as 'button';

    return (
      <Tooltip content={tooltip} isDisabled={!tooltip} {...tooltipProps}>
        <ComponentToRender
          {...{
            ref,
            className: clsx(
              styles.root,
              styles[variant],
              className,
              isDark ? styles.dark : styles.light,
              isPressed && styles.pressed,
              isFocusVisible && styles.focused,
              isDisabled && styles.disabled,
              isLarge ? 'gap-6' : 'gap-4'
            ),
            ...(withOnlyAria
              ? {
                  role: 'button',
                }
              : {
                  type: 'button',
                }),
            disabled: isDisabled,
            tabIndex: isDisabled ? -1 : 0,
            'data-is-focus-visible': isFocusVisible,
            ...mergeProps(pressProps, focusProps, other),
          }}
        >
          {!isWithRightIcon && iconElement}
          {!!children && (
            <Typography
              className={clsx(isLarge && 'py-2')}
              variant={
                isLarge
                  ? TypographyVariants.bodySmallStrong
                  : TypographyVariants.descriptionLargeStrong
              }
              skeletonSize={TextSkeletonSizes.large}
            >
              {children}
            </Typography>
          )}
          {isWithRightIcon && iconElement}
        </ComponentToRender>
      </Tooltip>
    );
  }
);
