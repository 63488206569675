import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import {
  AnyRoute,
  FileRoutesByPath,
  RegisteredRouter,
  useNavigate,
  useSearch,
} from '@tanstack/react-router';
import { ResolveSearch } from '@tanstack/react-router/dist/esm/useSearch';
import R from 'ramda';
import { ConditionalKeys } from 'type-fest';

import { capitalize } from '~/shared/helpers/string';
import { SerializableState } from '~/shared/types/serialization';

/**
 * Helper to integrate useSearchParamsState hook with Tanstack Router search params.
 * It gets search params with applied defaults from validateSearch Route prop
 */
export type ExtractSearchParamsFromRoute<TRoute extends AnyRoute> =
  ResolveSearch<
    RegisteredRouter,
    FileRoutesByPath[ConditionalKeys<
      FileRoutesByPath,
      { fullPath: TRoute['fullPath'] }
    >]['id'],
    true
  >;

type SearchParamsValues<T extends SerializableState> = {
  [K in keyof Required<T>]: T[K];
};

type SearchParamSetterKey<K> = `set${Capitalize<Extract<K, string>>}`;

type SearchParamsSetters<T extends SerializableState> = {
  [K in keyof Required<T> as SearchParamSetterKey<K>]: Dispatch<
    SetStateAction<T[K]>
  >;
};

type UseSearchParamsStateInterface<T extends SerializableState> =
  SearchParamsValues<T> & SearchParamsSetters<T>;

/**
 * Hook for using state, stored in the search part of the router
 */
export const useSearchParamsState = <
  T extends SerializableState,
>(): UseSearchParamsStateInterface<T> => {
  const navigate = useNavigate();
  const searchParamsMatched = useSearch({
    strict: false,
  });
  const [searchParams, setSearchParams] = useState(searchParamsMatched as T);

  const stateKeys = Object.keys(searchParams) as Extract<keyof T, string>[];

  const getSearchParamSetterKey = <K extends string>(key: K) =>
    `set${capitalize(key)}` as SearchParamSetterKey<typeof key>;

  const stateKeysHash = stateKeys.join('__');

  const searchParamsSetters = useMemo(
    () =>
      stateKeys.reduce((acc, key) => {
        const setter: Dispatch<
          SetStateAction<T[typeof key]>
        > = valueOrSetValue => {
          setSearchParams(currentParams => {
            let newValue;

            if (typeof valueOrSetValue === 'function') {
              const currentValue = currentParams[key];
              newValue = valueOrSetValue(currentValue);
            } else {
              newValue = valueOrSetValue;
            }

            return { ...currentParams, [key]: newValue };
          });
        };

        acc[getSearchParamSetterKey(key)] = setter as any;

        return acc;
      }, {} as SearchParamsSetters<T>),
    [stateKeysHash]
  );

  // Navigate, if we changed searchParams state
  useEffect(() => {
    if (R.equals(searchParams, searchParamsMatched as T)) return;

    navigate({
      search: searchParams as any,
      hash: prev => prev ?? '',
      replace: true,
      resetScroll: false,
    });
  }, [searchParams]);

  // Update state, if we navigate to different search params somewhere else
  useEffect(() => {
    if (R.equals(searchParams, searchParamsMatched as T)) return;

    setSearchParams(searchParamsMatched as T);
  }, [searchParamsMatched]);

  return {
    ...searchParams,
    ...searchParamsSetters,
  };
};
