import React from 'react';

import clsx from 'clsx';
import * as yup from 'yup';

import { SelectVariants } from '~/shared/components/Select';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { DiseaseAsyncSelect } from '~/entities/diseases';
import { DiseaseFragment } from '~/entities/diseases/gql/fragments/disease.graphql';

import formStyles from '~/styles/modules/form.module.scss';

export interface AddDiseaseIdsToListModalProps
  extends InjectedModalProps<AddDiseaseIdsToListModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * onSubmit handler for passing selected diseases to form
   */
  onSubmit: (selectedDiseases: string[]) => void;
  /**
   * Default selected diseases
   */
  defaultValues?: string[];
  /**
   * Diseases for selection
   */
  availableDiseases: DiseaseFragment[];
}

const FORM_ID = 'AddDiseaseIdsToListForm';

const SCHEMA = yup.object({
  diseaseIDs: yup.array(yup.string().required()).default([]),
});

type AddDiseaseIdsToListFormType = InferValidatedSchema<typeof SCHEMA>;

export const AddDiseaseIdsToListModal: React.FC<
  AddDiseaseIdsToListModalProps
> = ({ className, onSubmit, close, availableDiseases, defaultValues = [] }) => {
  const formContext = useForm<AddDiseaseIdsToListFormType>({
    schema: SCHEMA,
    defaultValues: { ...SCHEMA.getDefault(), diseaseIDs: defaultValues },
  });

  const handleSubmit = ({ diseaseIDs }: AddDiseaseIdsToListFormType) => {
    onSubmit(diseaseIDs);
    close();
  };

  return (
    <Modal
      {...{
        className,
        title: 'Добавление заболеваний в список',
        submitButtonProps: {
          form: FORM_ID,
          children: 'Добавить',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
          className: clsx(formStyles.singleColumnForm, 'gap-24'),
        }}
      >
        <DiseaseAsyncSelect
          {...{
            name: 'diseaseIDs',
            label: 'Пользовательское заболевание',
            isMulti: true,
            variant: SelectVariants.withItemsList,
            items: availableDiseases,
            queryOptions: {
              skip: true,
            },
          }}
        />
      </Form>
    </Modal>
  );
};
