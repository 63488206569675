export enum IconVariants {
  analytics = 'analytics',
  arrowDownRightTurn = 'arrowDownRightTurn',
  arrowUp = 'arrowUp',
  block = 'block',
  calendar = 'calendar',
  calendarCheck = 'calendarCheck',
  cancel = 'cancel',
  cancelCircleFilled = 'cancelCircleFilled',
  chart = 'chart',
  check = 'check',
  checkCircle = 'checkCircle',
  checkCircleFilled = 'checkCircleFilled',
  chevronDown = 'chevronDown',
  chevronRightCircle = 'chevronRightCircle',
  chevronRightCircleFilled = 'chevronRightCircleFilled',
  clipboardList = 'clipboardList',
  clock = 'clock',
  code = 'code',
  company = 'company',
  cow = 'cow',
  delete = 'delete',
  dot = 'dot',
  dots = 'dots',
  download = 'download',
  dragHandle = 'dragHandle',
  dragIndicator = 'dragIndicator',
  duplicate = 'duplicate',
  edit = 'edit',
  equally = 'equally',
  eyeShow = 'eyeShow',
  helpCircleFilled = 'helpCircleFilled',
  herriotConnected = 'herriotConnected',
  herriotDisabled = 'herriotDisabled',
  infoCircleFilled = 'infoCircleFilled',
  key = 'key',
  menu = 'menu',
  metricNegative = 'metricNegative',
  metricPositive = 'metricPositive',
  metricUnchanged = 'metricUnchanged',
  minus = 'minus',
  newTab = 'newTab',
  plus = 'plus',
  printer = 'printer',
  profile = 'profile',
  search = 'search',
  settings = 'settings',
  spinner = 'spinner',
  star = 'star',
  syringe = 'syringe',
  update = 'update',
  upload = 'upload',
  user = 'user',
  warningCircle = 'warningCircle',
  warningCircleFilled = 'warningCircleFilled',
  xClose = 'xClose',
}