import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { OperationFragmentDoc } from '../../gql/fragments/operation.graphql';
import { useOperationsQuery } from '../../gql/queries/operations.graphql';

/**
 * Select hook for a operation
 */
export const useOperationSelect = makeUseAsyncSelect({
  typeName: 'Operation',
  fragment: OperationFragmentDoc,
  useQuery: useOperationsQuery,
  getItemsFromQueryData: data => data.operations.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.operations.pageInfo,

  selectProps: {
    placeholder: 'Выберите действие',
    getItemValue: item => item.id,
    getItemText: item => item?.name ?? '',
  },
});

/**
 * Select for a operation
 */
export const OperationAsyncSelect =
  makeAsyncSelectFormComponent(useOperationSelect);
