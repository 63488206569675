import {
  MIN_ENTRIES_COUNT_TO_HIDE,
  MIN_ENTRIES_THRESHOLD_TO_HIDE,
} from './constants';

/**
 * Gets css span value for grid
 */
export const getSpanValue = (span: number | undefined) =>
  span ? `span ${span}` : undefined;

/**
 * Default getter for a table row key
 */
export const defaultGetItemKey = <T extends object>(item: T) =>
  'id' in item ? String(item.id) : '';

/**
 * Checks, if entries count is large enough for large data hiding to be applied
 */
export const getShouldHideLargeEntries = (entriesCount: number) =>
  entriesCount > MIN_ENTRIES_COUNT_TO_HIDE &&
  entriesCount - MIN_ENTRIES_COUNT_TO_HIDE > MIN_ENTRIES_THRESHOLD_TO_HIDE;
