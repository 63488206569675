import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { InjectionFragmentDoc } from '../../gql/fragments/injection.graphql';
import { useInjectionsQuery } from '../../gql/queries/injections.graphql';

/**
 * Select hook for an injection
 */
export const useInjectionSelect = makeUseAsyncSelect({
  typeName: 'Injection',
  fragment: InjectionFragmentDoc,
  useQuery: useInjectionsQuery,
  getItemsFromQueryData: data => data.injections.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.injections.pageInfo,

  selectProps: {
    placeholder: 'Выберите инъекцию',
    getItemValue: item => item.id,
    getItemText: item => item?.name ?? '',
  },
});

/**
 * Select for an injection
 */
export const InjectionAsyncSelect =
  makeAsyncSelectFormComponent(useInjectionSelect);
