import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { OperationFragmentDoc } from '../../../../entities/operations/gql/fragments/operation.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OperationsForCowsMovingQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  cowIDs: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type OperationsForCowsMovingQuery = { __typename?: 'Query', operationsForCowsMoving: { __typename?: 'OperationConnection', nodes: Array<{ __typename: 'Operation', id: string, name: string, isDefault: boolean }> } };


export const OperationsForCowsMovingDocument = gql`
    query operationsForCowsMoving($search: String, $ids: [ID!], $cowIDs: [ID!]!) {
  operationsForCowsMoving(search: $search, ids: $ids, cowIDs: $cowIDs) {
    nodes {
      ...Operation
    }
  }
}
    ${OperationFragmentDoc}`;

/**
 * __useOperationsForCowsMovingQuery__
 *
 * To run a query within a React component, call `useOperationsForCowsMovingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationsForCowsMovingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationsForCowsMovingQuery({
 *   variables: {
 *      search: // value for 'search'
 *      ids: // value for 'ids'
 *      cowIDs: // value for 'cowIDs'
 *   },
 * });
 */
export function useOperationsForCowsMovingQuery(baseOptions: Apollo.QueryHookOptions<OperationsForCowsMovingQuery, OperationsForCowsMovingQueryVariables> & ({ variables: OperationsForCowsMovingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperationsForCowsMovingQuery, OperationsForCowsMovingQueryVariables>(OperationsForCowsMovingDocument, options);
      }
export function useOperationsForCowsMovingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperationsForCowsMovingQuery, OperationsForCowsMovingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperationsForCowsMovingQuery, OperationsForCowsMovingQueryVariables>(OperationsForCowsMovingDocument, options);
        }
export function useOperationsForCowsMovingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OperationsForCowsMovingQuery, OperationsForCowsMovingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OperationsForCowsMovingQuery, OperationsForCowsMovingQueryVariables>(OperationsForCowsMovingDocument, options);
        }
export type OperationsForCowsMovingQueryHookResult = ReturnType<typeof useOperationsForCowsMovingQuery>;
export type OperationsForCowsMovingLazyQueryHookResult = ReturnType<typeof useOperationsForCowsMovingLazyQuery>;
export type OperationsForCowsMovingSuspenseQueryHookResult = ReturnType<typeof useOperationsForCowsMovingSuspenseQuery>;
export type OperationsForCowsMovingQueryResult = Apollo.QueryResult<OperationsForCowsMovingQuery, OperationsForCowsMovingQueryVariables>;