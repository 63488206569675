import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ProtocolFragmentDoc } from '../fragments/protocol.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProtocolsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type ProtocolsQuery = { __typename?: 'Query', protocols: { __typename?: 'ProtocolConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'ProtocolEdge', cursor: string, node: { __typename: 'Protocol', id: string, name: string, forYoung: boolean, forCows: boolean, processKind: Types.ProcessKindEnum, deactivatedAt?: string | null } }> } };


export const ProtocolsDocument = gql`
    query protocols($after: String, $first: Int, $search: String, $ids: [ID!]) {
  protocols(after: $after, first: $first, search: $search, ids: $ids) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...Protocol
      }
    }
  }
}
    ${ProtocolFragmentDoc}`;

/**
 * __useProtocolsQuery__
 *
 * To run a query within a React component, call `useProtocolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProtocolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProtocolsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useProtocolsQuery(baseOptions?: Apollo.QueryHookOptions<ProtocolsQuery, ProtocolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProtocolsQuery, ProtocolsQueryVariables>(ProtocolsDocument, options);
      }
export function useProtocolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProtocolsQuery, ProtocolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProtocolsQuery, ProtocolsQueryVariables>(ProtocolsDocument, options);
        }
export function useProtocolsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProtocolsQuery, ProtocolsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProtocolsQuery, ProtocolsQueryVariables>(ProtocolsDocument, options);
        }
export type ProtocolsQueryHookResult = ReturnType<typeof useProtocolsQuery>;
export type ProtocolsLazyQueryHookResult = ReturnType<typeof useProtocolsLazyQuery>;
export type ProtocolsSuspenseQueryHookResult = ReturnType<typeof useProtocolsSuspenseQuery>;
export type ProtocolsQueryResult = Apollo.QueryResult<ProtocolsQuery, ProtocolsQueryVariables>;