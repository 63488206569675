import React from 'react';

import clsx from 'clsx';
import * as yup from 'yup';

import { SelectVariants } from '~/shared/components/Select';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { OperationAsyncSelect } from '~/entities/operations';
import { OperationFragment } from '~/entities/operations/gql/fragments/operation.graphql';

import formStyles from '~/styles/modules/form.module.scss';

const FORM_ID = 'AddEventIdsToListForm';

const SCHEMA = yup.object({
  operationIDs: yup.array(yup.string().required()).default([]),
});

type AddEventIdsToListFormType = InferValidatedSchema<typeof SCHEMA>;

export interface AddOperationIdsToListModalProps
  extends InjectedModalProps<AddOperationIdsToListModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * onSubmit handler for passing selected events to form
   */
  onSubmit: (selectedEvents: string[]) => void;
  /**
   * Default selected operations
   */
  defaultValues?: string[];
  /**
   * Operations for selection
   */
  availableOperations: OperationFragment[];
}

export const AddOperationIdsToListModal: React.FC<
  AddOperationIdsToListModalProps
> = ({
  className,
  close,
  onSubmit,
  availableOperations,
  defaultValues = [],
}) => {
  const formContext = useForm<AddEventIdsToListFormType>({
    schema: SCHEMA,
    defaultValues: { ...SCHEMA.getDefault(), operationIDs: defaultValues },
  });

  const handleSubmit = ({ operationIDs }: AddEventIdsToListFormType) => {
    onSubmit(operationIDs);
    close();
  };

  return (
    <Modal
      {...{
        className,
        title: 'Добавление действий в список',
        submitButtonProps: {
          form: FORM_ID,
          children: 'Добавить',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
          className: clsx(formStyles.singleColumnForm, 'gap-24'),
        }}
      >
        <OperationAsyncSelect
          {...{
            name: 'operationIDs',
            label: 'Ветеринарное действие',
            isMulti: true,
            variant: SelectVariants.withItemsList,
            items: availableOperations,
            queryOptions: {
              skip: true,
            },
          }}
        />
      </Form>
    </Modal>
  );
};
