import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { OperationFragmentDoc } from '../fragments/operation.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OperationsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type OperationsQuery = { __typename?: 'Query', operations: { __typename?: 'OperationConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'OperationEdge', cursor: string, node: { __typename: 'Operation', id: string, name: string, isDefault: boolean } }> } };


export const OperationsDocument = gql`
    query operations($after: String, $first: Int, $search: String, $ids: [ID!]) {
  operations(after: $after, first: $first, search: $search, ids: $ids) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...Operation
      }
    }
  }
}
    ${OperationFragmentDoc}`;

/**
 * __useOperationsQuery__
 *
 * To run a query within a React component, call `useOperationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useOperationsQuery(baseOptions?: Apollo.QueryHookOptions<OperationsQuery, OperationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperationsQuery, OperationsQueryVariables>(OperationsDocument, options);
      }
export function useOperationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperationsQuery, OperationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperationsQuery, OperationsQueryVariables>(OperationsDocument, options);
        }
export function useOperationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OperationsQuery, OperationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OperationsQuery, OperationsQueryVariables>(OperationsDocument, options);
        }
export type OperationsQueryHookResult = ReturnType<typeof useOperationsQuery>;
export type OperationsLazyQueryHookResult = ReturnType<typeof useOperationsLazyQuery>;
export type OperationsSuspenseQueryHookResult = ReturnType<typeof useOperationsSuspenseQuery>;
export type OperationsQueryResult = Apollo.QueryResult<OperationsQuery, OperationsQueryVariables>;