import React from 'react';

import clsx from 'clsx';

import { SkeletonPlaceholder } from '~/shared/components/Skeleton';
import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableColumnConfig,
} from '~/shared/components/Table';
import { TextLink } from '~/shared/components/TextLink';

import { formatTime } from '~/services/dateTime';

import { getCowIdentifier } from '~/entities/cows';
import { formatPenGroup } from '~/entities/penGroups';

import { MilkingParlorMixedMilkingReportRowEntryFragment } from '../../gql/fragments/milkingParlorMixedMilkingReportRowEntry.graphql';

type TableRow =
  | MilkingParlorMixedMilkingReportRowEntryFragment
  | SkeletonPlaceholder;

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Milking report rows to display
   */
  milkingParlorMixedMilkingReportRows: TableRow[];
}

const IDENTIFIER_COLUMN_WIDTH_PX = 204;
const GROUP_COLUMN_WIDTH_PX = 220;
const ATTACH_TIME_COLUMN_WIDTH_PX = 152;
const STALL_NUMBER_COLUMN_WIDTH_PX = 124;

export const MilkingMixedMilkingReportTable: React.FC<Props> = ({
  className,
  milkingParlorMixedMilkingReportRows,
}) => {
  const columnConfigs: TableColumnConfig<TableRow>[] = [
    {
      title: 'Номер животного',
      key: 'identifier',
      renderCellContent: ({ cow }) => (
        <TextLink
          to="/$companyId/user/cows/$cowId"
          params={prev => ({ ...prev, cowId: cow?.id ?? '' })}
        >
          {getCowIdentifier(cow)}
        </TextLink>
      ),
      width: IDENTIFIER_COLUMN_WIDTH_PX,
    },
    {
      title: 'Плановая группа доения',
      key: 'expectedPenGroup',
      renderCellContent: row => formatPenGroup(row.expectedPenGroup),
      width: GROUP_COLUMN_WIDTH_PX,
    },
    {
      title: 'Фактическая группа доения',
      key: 'actualPenGroup',
      renderCellContent: row => formatPenGroup(row.actualPenGroup),
      width: GROUP_COLUMN_WIDTH_PX,
    },
    {
      title: 'Время начала доения',
      columnClassName: 'text-right',
      key: 'attachTime',
      renderCellContent: row => formatTime(row.attachTime),
      width: ATTACH_TIME_COLUMN_WIDTH_PX,
    },
    {
      title: 'Номер аппарата',
      columnClassName: 'text-right',
      key: 'stallNumber',
      renderCellContent: row => row.stallNumber,
      width: STALL_NUMBER_COLUMN_WIDTH_PX,
    },
    FILLER_COLUMN_CONFIG,
  ];

  return (
    <Table<TableRow>
      {...{
        className: clsx(className, 'min-w-full w-min'),
        items: milkingParlorMixedMilkingReportRows,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
      }}
    />
  );
};
