import { createFileRoute } from '@tanstack/react-router';

import { getAsyncListState } from '~/shared/components/AsyncList';
import {
  FunctionButton,
  FunctionButtonVariants,
} from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableColumnConfig,
} from '~/shared/components/Table';
import { TABLE_ROWS_DEFAULT_SKELETON_COUNT } from '~/shared/constants';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';
import {
  ExtractSearchParamsFromRoute,
  useSearchParamsState,
} from '~/shared/hooks/useSearchParamsState';

import {
  SearchableSearchParams,
  WithSearchParamsValidation,
} from '~/services/navigation';

import { useDiseasesPaginatedQuery } from '~/entities/diseases';
import { DiseaseFragment } from '~/entities/diseases/gql/fragments/disease.graphql';

const NAME_COLUMN_WIDTH_PX = 300;
const IS_DEFAULT_COLUMN_WIDTH_PX = 184;

export const Route = createFileRoute(
  '/$companyId/_layout/user/events/all/diseases'
)({
  wrapInSuspense: true,
  component: DiseasesPage,
  validateSearch: ({
    search,
  }: WithSearchParamsValidation<SearchableSearchParams>) => ({
    search: search ?? '',
  }),
});

function DiseasesPage() {
  const { search: debouncedSearch } =
    useSearchParamsState<ExtractSearchParamsFromRoute<typeof Route>>();

  const { isSearchActive } = useDebouncedSearch({
    search: debouncedSearch,
  });

  const queryVariables = {
    search: debouncedSearch,
  };

  const { items, ...asyncProps } = useDiseasesPaginatedQuery({
    variables: queryVariables,
  });

  const columnConfigs: TableColumnConfig<DiseaseFragment>[] = [
    {
      title: 'Название активности',
      key: 'name',
      itemField: 'name',
      width: NAME_COLUMN_WIDTH_PX,
    },
    {
      title: 'Тип',
      key: 'isDefault',
      renderCellContent: item =>
        item.isDefault ? 'Стандартное' : 'Пользовательское',
      width: IS_DEFAULT_COLUMN_WIDTH_PX,
    },
    FILLER_COLUMN_CONFIG,
  ];

  const asyncListState = getAsyncListState({
    items,
    skeletonItemsCount: TABLE_ROWS_DEFAULT_SKELETON_COUNT,
    isSearchActive,
    isLoading: asyncProps.isLoading,
  });

  return (
    <Table<DiseaseFragment>
      {...{
        asyncListState,
        className: 'min-w-full w-min',
        items,
        columnConfigs,
        noItemsMessage: 'Заболевания пока не добавлены',
        noSearchItemsMessage: 'Заболевания не найдены',
        noSearchItemsDescription:
          'По вашему запросу нет подходящих заболеваний. Используйте другое название',
        isSearchActive,
        isSelectable: item => !item.isDefault,
        renderActionBar: (
          selectedItems,
          { isSingleItemSelected, commonFunctionButtonProps }
        ) => (
          <>
            <FunctionButton
              {...{
                iconVariant: IconVariants.edit,
                isDisabled: !isSingleItemSelected,
                children: 'Редактировать',
                onPress: () => {},
                ...commonFunctionButtonProps,
              }}
            />
            <FunctionButton
              {...{
                iconVariant: IconVariants.delete,
                variant: FunctionButtonVariants.failure,
                isDisabled: !isSingleItemSelected,
                children: 'Удалить',
                onPress: () => {},
                ...commonFunctionButtonProps,
              }}
            />
          </>
        ),
        ...asyncProps,
      }}
    />
  );
}
