import clsx from 'clsx';

import { Checkbox } from '~/shared/components/Checkbox';
import {
  FunctionButton,
  FunctionButtonSizes,
} from '~/shared/components/FunctionButton';
import { TextSkeletonSizes } from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps } from '~/shared/helpers/mergeProps';

import { SizeVariants } from '~/styles/__generated__/token-variants';

import { ITEM_ACTIONS_COLUMN_KEY } from '../../constants';
import { getSpanValue } from '../../helpers';
import styles from '../../index.module.scss';
import { TableColumnConfigInner, TableProps, TableThemes } from '../../types';

interface Props<
  Item extends object,
  CellData,
  SkeletonItemsCount extends number | undefined = number,
> {
  /**
   * All props passed to the table component
   */
  tableProps: TableProps<Item, CellData, SkeletonItemsCount>;

  /**
   * If true, renders additional column for an expand row icon
   */
  isTableWithExpandableRows: boolean;
  /**
   * Total number of columns in the table (defined by the user and the component itself)
   */
  columnsCount: number;
  /**
   * Column configs without grouping columns
   */
  flattenColumnConfigs: TableColumnConfigInner<Item, SkeletonItemsCount>[];

  /**
   * Array of precalculated items, that can be selected
   */
  selectableItems: Item[];
  /**
   * Array of currently selected row items
   */
  selectedItems: Item[];
  /**
   * Called, when the "select all" checkbox selection is changed
   */
  onSelectAll?: (isAllItemsSelected: boolean) => void;
}

export const TableHeader = <
  Item extends object,
  CellData,
  SkeletonItemsCount extends number | undefined = number,
>({
  tableProps,

  isTableWithExpandableRows,
  columnsCount,
  flattenColumnConfigs,

  selectableItems,
  selectedItems,
  onSelectAll,
}: Props<Item, CellData, SkeletonItemsCount>) => {
  const {
    theme = TableThemes.primary,

    columnConfigs,

    renderItemActions,

    isSelectable = false,
    renderActionBar,

    printableTitle,
  } = tableProps;

  const headerTypographyVariant =
    theme === TableThemes.primary
      ? TypographyVariants.descriptionLargeStrong
      : TypographyVariants.descriptionMediumStrong;

  const hasNestedColumnsLayout = columnConfigs.some(
    config => 'nestedColumns' in config
  );
  const nestedHeaderRowSpan = hasNestedColumnsLayout ? 2 : undefined;

  // Render counter for span calculations of header columns without nested columns,
  // which should span to full header height
  let nonNestedColumnsToSkip =
    (isTableWithExpandableRows ? 1 : 0) + (isSelectable ? 1 : 0);

  const renderHeaderColumn = (
    configToRender: TableColumnConfigInner<Item, SkeletonItemsCount>,
    isFirstRow: boolean
  ) => {
    if (
      isFirstRow &&
      configToRender.groupingColumnConfig &&
      !configToRender.isNestedLeft
    ) {
      return null;
    }

    if (
      !isFirstRow &&
      !configToRender.isNested &&
      !configToRender.isSplittedHeader
    ) {
      nonNestedColumnsToSkip += 1;
      return null;
    }

    const gridColumnStart =
      isFirstRow || !nonNestedColumnsToSkip
        ? undefined
        : nonNestedColumnsToSkip + 1;

    if (!isFirstRow) {
      nonNestedColumnsToSkip = 0;
    }

    const config = isFirstRow
      ? (configToRender.groupingColumnConfig ?? configToRender)
      : configToRender;

    const isGroupingConfig = !!config.nestedColumns;

    const colSpan = config.nestedColumns?.length;
    const rowSpan =
      isFirstRow && !config.nestedColumns && !config.isSplittedHeader
        ? nestedHeaderRowSpan
        : undefined;

    return (
      <td
        key={config.key}
        {...{
          colSpan,
          rowSpan,

          className: clsx(
            styles.headerCell,
            config.isSticky && styles.stickyCell,
            config.columnClassName,
            config.headerClassName,
            {
              [styles.groupedCellLeft]:
                configToRender.isNestedLeft || isGroupingConfig,
              [styles.groupedCellRight]:
                configToRender.isNestedRight || isGroupingConfig,
            }
          ),
          style: {
            gridRow: getSpanValue(rowSpan),
            gridColumnStart,
            gridColumnEnd: getSpanValue(colSpan),
          },
        }}
      >
        <Typography
          {...{
            variant: headerTypographyVariant,
            skeletonSize: TextSkeletonSizes.small,
            ...config.headerTypographyProps,
          }}
        >
          {config.title}
          {!!config.functionButtonProps && (
            <FunctionButton
              {...mergeProps(config.functionButtonProps, {
                className: styles.headerIcon,
              })}
            />
          )}
        </Typography>
      </td>
    );
  };

  const isAllItemsSelected = selectedItems.length === selectableItems.length;

  return (
    <thead
      {...{
        className: styles.thead,
        ['data-is-sticky-thead']: true,
      }}
    >
      {!!printableTitle && (
        <tr className={clsx(styles.headerRow, 'only-for-print')}>
          <th
            {...{
              style: {
                gridRow: getSpanValue(columnsCount),
              },
            }}
          >
            <Typography variant={TypographyVariants.heading2}>
              {printableTitle}
            </Typography>
          </th>
        </tr>
      )}
      <tr className={styles.headerRow}>
        {isTableWithExpandableRows && (
          <th
            {...{
              className: clsx(styles.headerCell, styles.functionCell),
              rowSpan: nestedHeaderRowSpan,
              style: {
                gridRow: getSpanValue(nestedHeaderRowSpan),
              },
            }}
          />
        )}
        {isSelectable && (
          <th
            {...{
              className: clsx(styles.headerCell, styles.functionCell),
              rowSpan: nestedHeaderRowSpan,
              style: {
                gridRow: getSpanValue(nestedHeaderRowSpan),
              },
            }}
          >
            <div className="grid place-items-center">
              <Checkbox
                {...{
                  name: `selectAll`,
                  size: SizeVariants.size16,
                  value: isAllItemsSelected && !!selectableItems.length,
                  isDisabled: !selectableItems.length,
                  isIndeterminate:
                    !isAllItemsSelected && !!selectedItems.length,
                  onValueChange: newValue => onSelectAll?.(newValue),
                }}
              />
            </div>
          </th>
        )}

        {!selectedItems.length &&
          flattenColumnConfigs.map(config => renderHeaderColumn(config, true))}
        {!!selectedItems.length && (
          <th
            {...{
              className: styles.actionBarCell,
              colSpan: flattenColumnConfigs.length,
              style: {
                gridColumn: getSpanValue(flattenColumnConfigs.length),
              },
            }}
          >
            <div className="flex items-center gap-12">
              <Typography
                className={styles.selectedItemsCount}
                variant={TypographyVariants.descriptionLargeStrong}
              >
                Выбрано: {selectedItems.length}
              </Typography>

              <div className="flex gap-20">
                {renderActionBar?.(selectedItems, {
                  isSingleItemSelected: selectedItems.length === 1,
                  commonFunctionButtonProps: {
                    size: FunctionButtonSizes.medium20,
                  },
                })}
              </div>
            </div>
          </th>
        )}

        {renderItemActions && (
          <th
            key={ITEM_ACTIONS_COLUMN_KEY}
            {...{
              className: clsx(styles.headerCell, styles.itemActionCell),
              rowSpan: nestedHeaderRowSpan,
              style: {
                gridRow: getSpanValue(nestedHeaderRowSpan),
              },
            }}
          />
        )}
      </tr>
      {hasNestedColumnsLayout && (
        <tr className={styles.secondHeaderRow}>
          {flattenColumnConfigs.map(config =>
            renderHeaderColumn(config, false)
          )}
        </tr>
      )}
    </thead>
  );
};
