import React from 'react';

import clsx from 'clsx';
import dayjs from 'dayjs';

import { isSkeletonPlaceholder } from '~/shared/components/Skeleton';
import {
  Table,
  TableColumnConfig,
  TableProps,
} from '~/shared/components/Table';
import { TextLink } from '~/shared/components/TextLink';
import { PartialExcept } from '~/shared/types/utility';

import { CowShortFragment } from '../../gql/fragments/cowShort.graphql';
import { CowStateBadge } from '../CowStateBadge';

type Props = PartialExcept<TableProps<CowShortFragment>, 'items'>;

const COLUMN_WIDTH_PX = 132;

const formatAgeByDateOfBirth = (birthDateProp: string) => {
  const now = dayjs();
  const birthDate = dayjs(birthDateProp);

  const yearsDiff = now.diff(birthDate, 'years');
  const dateOfLastBirthDay = birthDate.add(yearsDiff, 'years');

  const monthsDiff = now.diff(dateOfLastBirthDay, 'months');
  const daysDiff = now.diff(
    dateOfLastBirthDay.add(monthsDiff, 'months'),
    'days'
  );

  const formattedYears = yearsDiff ? `${yearsDiff} лет` : '';
  const formattedMonths = monthsDiff ? `${monthsDiff} месяцев` : '';
  const formattedDays = yearsDiff ? '' : `${daysDiff} дней`;

  return `${formattedYears} ${formattedMonths} ${formattedDays}`;
};

export const CowsTable: React.FC<Props> = ({ className, ...tableProps }) => {
  const columnConfigs: TableColumnConfig<CowShortFragment>[] = [
    {
      title: 'Номер животного',
      key: 'identifier',
      renderCellContent: ({ id, identifier }) => (
        <TextLink
          {...{
            to: '/$companyId/user/cows/$cowId',
            params: prev => ({
              ...prev,
              cowId: id,
            }),
          }}
        >
          {identifier}
        </TextLink>
      ),
      width: COLUMN_WIDTH_PX,
    },
    {
      title: 'Статус',
      key: 'state',
      renderCellContent: cow =>
        isSkeletonPlaceholder(cow) ? '' : <CowStateBadge state={cow.state} />,
      width: COLUMN_WIDTH_PX,
    },
    {
      title: 'Возраст',
      key: 'dateOfBirth',
      renderCellContent: cow =>
        isSkeletonPlaceholder(cow)
          ? ''
          : formatAgeByDateOfBirth(cow.dateOfBirth),
    },
  ];

  return (
    <Table
      {...{
        columnConfigs,
        className: clsx('col-span-full min-w-full w-min', className),
        noItemsMessage: 'Нет данных для отображения',
        ...tableProps,
      }}
    />
  );
};
