import React from 'react';

import clsx from 'clsx';
import R from 'ramda';

import { AsyncListProps } from '~/shared/components/AsyncList';
import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { isSkeletonPlaceholder } from '~/shared/components/Skeleton';
import { Table, TableColumnConfig } from '~/shared/components/Table';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatInt } from '~/shared/helpers/number';

import { formatDate } from '~/services/dateTime';
import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { useConfirm } from '~/services/modals';
import {
  Callout,
  CalloutSizes,
  useNotifications,
} from '~/services/notifications';

import panelStyles from '~/styles/modules/panel.module.scss';

import { TestMilkingUploadFragment } from '../../gql/fragments/testMilkingUpload.graphql';
import { useDeleteTestMilkingUploadMutation } from '../../gql/mutations/deleteTestMilkingUpload.graphql';
import { useDismissTestMilkingUploadNotFoundCowsMutation } from '../../gql/mutations/dismissTestMilkingUploadNotFoundCows.graphql';
import {
  TestMilkingUploadsDocument,
  TestMilkingUploadsQueryVariables,
} from '../../gql/queries/testMilkingUploads.graphql';
import { updateTestMilkingUploadFragment } from '../../helpers';
import { useExportTestMilkingUploadNotFoundCows } from '../../hooks';
import { useLoadTestMilkingPartialResultsModal } from '../../modals';
import { TestMilkingsTable } from '../TestMilkingsTable';
import styles from './index.module.scss';

interface Props extends Partial<AsyncListProps<TestMilkingUploadFragment>> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Semen doses to display in table
   */
  testMilkingUploads: TestMilkingUploadFragment[];
  /**
   * Query variables for the semen doses codes query
   */
  queryVariables: TestMilkingUploadsQueryVariables;
}

const HAPPENED_AT_COLUMN_WIDTH_PX = 112;
const COWS_COUNT_COLUMN_WIDTH_PX = 156;
const UPLOAD_ISSUES_COLUMN_WIDTH = 'minmax(63ch, 1fr)';

export const TestMilkingUploadsTable: React.FC<Props> = ({
  className,
  testMilkingUploads,
  queryVariables,
  ...asyncProps
}) => {
  const { sendNeutralToast } = useNotifications();

  const { open: openLoadTestMilkingPartialResultsModal } =
    useLoadTestMilkingPartialResultsModal();

  const { exportTestMilkingUploadNotFoundCows } =
    useExportTestMilkingUploadNotFoundCows();

  const [dismissTestMilkingUploadNotFoundCows] =
    useDismissTestMilkingUploadNotFoundCowsMutation();

  const [deleteTestMilkingUploadMutation] =
    useDeleteTestMilkingUploadMutation();
  const confirmDelete = useConfirm();

  const columnConfigs: TableColumnConfig<TestMilkingUploadFragment>[] = [
    {
      title: 'Дата',
      key: 'happenedAt',
      renderCellContent: ({ happenedAt }) => formatDate(happenedAt),
      width: HAPPENED_AT_COLUMN_WIDTH_PX,
    },
    {
      title: 'Количество животных',
      key: 'cowsCount',
      columnClassName: 'text-right',
      renderCellContent: ({ cowsCount }) => formatInt(cowsCount),
      width: COWS_COUNT_COLUMN_WIDTH_PX,
    },
    {
      title: '',
      key: 'hasUploadIssues',
      cellClassName: testMilkingUpload => {
        return testMilkingUpload.hasUploadIssues
          ? styles.uploadIssues
          : undefined;
      },
      renderCellContent: testMilkingUpload => {
        if (
          isSkeletonPlaceholder(testMilkingUpload) ||
          !testMilkingUpload.hasUploadIssues
        ) {
          return null;
        }

        return (
          <Callout
            {...{
              message: 'В хозяйстве не найдены некоторые коровы из файла',
              size: CalloutSizes.small8,
              functionButtonProps: [
                {
                  children: 'Подробнее',
                  onPress: () => {
                    openLoadTestMilkingPartialResultsModal({
                      testMilkingUpload,
                    });
                  },
                },
                {
                  children: 'Скачать ненайденных',
                  onPress: () => {
                    exportTestMilkingUploadNotFoundCows(testMilkingUpload.id);
                  },
                },
              ],
              onClose: () => {
                dismissTestMilkingUploadNotFoundCows({
                  variables: {
                    testMilkingUploadID: testMilkingUpload.id,
                  },
                  optimisticResponse: {
                    dismissTestMilkingUploadNotFoundCows: null,
                  },
                  update: updateTestMilkingUploadFragment(
                    testMilkingUpload.id,
                    draft => {
                      draft.hasUploadIssues = false;
                    }
                  ),
                });
              },
            }}
          />
        );
      },
      width: UPLOAD_ISSUES_COLUMN_WIDTH,
    },
  ];

  const deleteTestMilkingUpload = (
    testMilkingUpload: TestMilkingUploadFragment
  ) =>
    confirmDelete({
      title: 'Удаление результатов контрольной дойки',
      message: (
        <div className="grid gap-12">
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Вы хотите удалить результаты контрольной дойки всех коров от{' '}
            <Typography variant={TypographyVariants.bodySmallStrong}>
              {formatDate(testMilkingUpload.happenedAt)}
            </Typography>{' '}
            ?
          </Typography>
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Это действие невозможно отменить.
          </Typography>
        </div>
      ),
      isDelete: true,
    }).then(isConfirmed => {
      if (!isConfirmed) return;

      deleteTestMilkingUploadMutation({
        variables: {
          id: testMilkingUpload.id,
        },
        optimisticResponse: { deleteTestMilkingUpload: null },
        update: R.juxt([
          makeDeleteFragmentFromQuery({
            typeName: 'TestMilkingUpload',
            query: TestMilkingUploadsDocument,
            variables: queryVariables,
            queryName: 'testMilkingUploads',
          })(testMilkingUpload.id),
          makeDeleteQueriesByNameWithoutVariables(
            'testMilkingUploads',
            queryVariables
          ),
        ]),
      });

      sendNeutralToast('Результаты контрольной дойки удалены');
    });

  return (
    <Table<TestMilkingUploadFragment>
      {...{
        className: clsx(className, panelStyles.panel, 'min-w-full w-min'),
        items: testMilkingUploads,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
        hasExpandableRowContent: testMilkingUpload =>
          !!testMilkingUpload.cowsCount,
        renderExpandableRowContent: testMilkingUpload => (
          <TestMilkingsTable
            {...{
              className: 'col-span-full',
              testMilkingUploadID: testMilkingUpload.id,
            }}
          />
        ),
        itemActionCellClassName: testMilkingUpload =>
          // Upload issues callout should span to item action cell by design
          testMilkingUpload.hasUploadIssues ? 'hidden' : undefined,
        renderItemActions: testMilkingUpload =>
          !isSkeletonPlaceholder(testMilkingUpload) && (
            <FunctionButton
              {...{
                iconVariant: IconVariants.delete,
                tooltip: 'Удалить результаты контрольной дойки',
                onPress: () => deleteTestMilkingUpload(testMilkingUpload),
              }}
            />
          ),
        ...asyncProps,
      }}
    />
  );
};
