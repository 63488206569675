import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowsCopyKeyFragmentDoc } from '../fragments/cowsCopyKey.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCowsCopyKeyMutationVariables = Types.Exact<{
  input: Types.CreateCowsCopyKeyInput;
}>;


export type CreateCowsCopyKeyMutation = { __typename?: 'Mutation', createCowsCopyKey: { __typename: 'CowsCopyKey', key: string, reason: Types.CowsCopyReasonEnum, departDate: string, arriveDate?: string | null, activatedAt?: string | null, toCompanyInn?: string | null, toCompanyName?: string | null, creator: { __typename: 'User', id: string, email: any, firstName: string, lastName: string, middleName: string, companies: { __typename?: 'UserCompanyConnection', edges: Array<{ __typename?: 'UserCompanyEdge', node: { __typename: 'Company', id: string }, blueprintRoles?: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> | null }> }, integrator?: { __typename: 'Integrator', id: string, name: string } | null }, company: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean }, toCompany?: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean } | null, cows: Array<{ __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }>, operations: Array<{ __typename: 'Operation', id: string, name: string, isDefault: boolean }>, diseases: Array<{ __typename: 'Disease', id: string, name: string, isDefault: boolean, shouldSpecifyLimb: boolean }>, inseminationBulls: Array<{ __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null }> } };


export const CreateCowsCopyKeyDocument = gql`
    mutation createCowsCopyKey($input: CreateCowsCopyKeyInput!) {
  createCowsCopyKey(input: $input) {
    ...CowsCopyKey
  }
}
    ${CowsCopyKeyFragmentDoc}`;
export type CreateCowsCopyKeyMutationFn = Apollo.MutationFunction<CreateCowsCopyKeyMutation, CreateCowsCopyKeyMutationVariables>;

/**
 * __useCreateCowsCopyKeyMutation__
 *
 * To run a mutation, you first call `useCreateCowsCopyKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCowsCopyKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCowsCopyKeyMutation, { data, loading, error }] = useCreateCowsCopyKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCowsCopyKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCowsCopyKeyMutation, CreateCowsCopyKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCowsCopyKeyMutation, CreateCowsCopyKeyMutationVariables>(CreateCowsCopyKeyDocument, options);
      }
export type CreateCowsCopyKeyMutationHookResult = ReturnType<typeof useCreateCowsCopyKeyMutation>;
export type CreateCowsCopyKeyMutationResult = Apollo.MutationResult<CreateCowsCopyKeyMutation>;
export type CreateCowsCopyKeyMutationOptions = Apollo.BaseMutationOptions<CreateCowsCopyKeyMutation, CreateCowsCopyKeyMutationVariables>;