import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import R from 'ramda';

import { getAsyncListState } from '~/shared/components/AsyncList';
import { Button, ButtonProps } from '~/shared/components/Button';
import { ContextMenuButton } from '~/shared/components/ContextMenuButton';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { Input, InputVariants } from '~/shared/components/Input';
import { MenuItemVariants } from '~/shared/components/Menu';
import { isSkeletonPlaceholder } from '~/shared/components/Skeleton';
import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableColumnConfig,
} from '~/shared/components/Table';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { TABLE_ROWS_DEFAULT_SKELETON_COUNT } from '~/shared/constants';
import { wrapJsxIf } from '~/shared/helpers/render';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';

import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { useConfirm } from '~/services/modals';
import { useNotifications } from '~/services/notifications';

import {
  useEditInseminationSchemeModal,
  useInseminationSchemesPaginatedQuery,
} from '~/entities/inseminationSchemes';
import { InseminationSchemeFragment } from '~/entities/inseminationSchemes/gql/fragments/inseminationScheme.graphql';
import { useDeleteInseminationSchemeMutation } from '~/entities/inseminationSchemes/gql/mutations/deleteInseminationScheme.graphql';
import { InseminationSchemesDocument } from '~/entities/inseminationSchemes/gql/queries/inseminationSchemes.graphql';

import { PageHeader } from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

const BASE_COLUMN_WIDTH_PX = 300;

export const Route = createFileRoute(
  '/$companyId/_layout/user/entities/insemination-schemes/'
)({
  wrapInSuspense: true,
  component: InseminationSchemesPage,
});

function InseminationSchemesPage() {
  const { sendNeutralToast } = useNotifications();

  const { open: openEditInseminationSchemeModal } =
    useEditInseminationSchemeModal();

  const [deleteInseminationScheme] = useDeleteInseminationSchemeMutation();
  const confirmDelete = useConfirm();

  const { search, setSearch, debouncedSearch, isSearchActive } =
    useDebouncedSearch();

  const queryVariables = {
    search: debouncedSearch,
  };

  const { items, ...asyncProps } = useInseminationSchemesPaginatedQuery({
    variables: queryVariables,
  });

  const addButtonProps = {
    iconVariant: IconVariants.plus,
    children: 'Добавить схему',
    onPress: () => openEditInseminationSchemeModal(),
  } satisfies ButtonProps;

  const filtersElement = (
    <div className={clsx('flex gap-16 mb-24', layoutStyles.limitedContainer)}>
      <Input
        {...{
          name: 'search',
          placeholder: 'Поиск по названию или коду',
          value: search,
          onValueChange: setSearch,
          className: 'default-control',
          variant: InputVariants.search,
        }}
      />
    </div>
  );

  const columnConfigs: TableColumnConfig<InseminationSchemeFragment>[] = [
    {
      title: 'Название схемы',
      key: 'name',
      itemField: 'name',
      width: BASE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Тип',
      key: 'isDefault',
      renderCellContent: item =>
        item.isDefault ? 'Стандартная' : 'Пользовательская',
      width: BASE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Код',
      key: 'code',
      itemField: 'code',
      width: BASE_COLUMN_WIDTH_PX,
    },
    FILLER_COLUMN_CONFIG,
  ];

  const asyncListState = getAsyncListState({
    items,
    skeletonItemsCount: TABLE_ROWS_DEFAULT_SKELETON_COUNT,
    isSearchActive,
    isLoading: asyncProps.isLoading,
  });

  const tableElement = (
    <Table<InseminationSchemeFragment>
      {...{
        asyncListState,
        className: 'min-w-full w-min',
        items,
        columnConfigs,
        filtersElement,
        shouldNoItemsMessageHideTable: true,
        noItemsMessage: (
          <div
            className={clsx(
              layoutStyles.fillLeftoverHeightContainer,
              layoutStyles.limitedContainer,
              panelStyles.panel,
              'p-24 grid place-items-center'
            )}
          >
            <DataBlockedMessage
              {...{
                isLarge: true,
                className: 'p-24',
                message: 'Схемы осеменения пока не добавлены',
                buttonProps: addButtonProps,
              }}
            />
          </div>
        ),
        noSearchItemsMessage: 'Схемы осеменения не найдены',
        noSearchItemsDescription:
          'По вашему запросу нет подходящих схем осеменения. Используйте другое название',
        isSearchActive,
        renderItemActions: inseminationScheme =>
          !isSkeletonPlaceholder(inseminationScheme) && (
            <ContextMenuButton
              {...{
                tooltip: 'Действия со схемой',
                items: [
                  {
                    content: 'Редактировать',
                    onPress: () => {
                      openEditInseminationSchemeModal({
                        inseminationScheme,
                      });
                    },
                  },
                  {
                    variant: MenuItemVariants.destructive,
                    content: 'Удалить',
                    onPress: () => {
                      confirmDelete({
                        title: 'Удаление схемы осеменения',
                        message: (
                          <div className="grid gap-12">
                            <Typography
                              tag="p"
                              variant={TypographyVariants.bodySmall}
                            >
                              Вы хотите удалить схему{' '}
                              <Typography
                                variant={TypographyVariants.bodySmallStrong}
                              >
                                {inseminationScheme.name}
                              </Typography>
                              ?
                            </Typography>
                            <Typography
                              tag="p"
                              variant={TypographyVariants.bodySmall}
                            >
                              Это действие невозможно отменить.
                            </Typography>
                          </div>
                        ),
                        isDelete: true,
                      }).then(isConfirmed => {
                        if (!isConfirmed) return;

                        deleteInseminationScheme({
                          variables: {
                            id: inseminationScheme.id,
                          },
                          optimisticResponse: {
                            deleteInseminationScheme: null,
                          },
                          update: R.juxt([
                            makeDeleteFragmentFromQuery({
                              typeName: 'InseminationScheme',
                              query: InseminationSchemesDocument,
                              variables: queryVariables,
                              queryName: 'inseminationSchemes',
                            })(inseminationScheme.id),
                            makeDeleteQueriesByNameWithoutVariables(
                              'inseminationSchemes',
                              queryVariables
                            ),
                          ]),
                        });
                        sendNeutralToast('Схема осеменения удалена');
                      });
                    },
                  },
                ],
              }}
            />
          ),
        ...asyncProps,
      }}
    />
  );

  return (
    <>
      <PageHeader
        {...{
          className: layoutStyles.limitedContainer,
          title: 'Схемы осеменения',
          rightContent: !asyncListState.isItemsNotCreated && (
            <Button key="addInseminationScheme" {...addButtonProps} />
          ),
        }}
      />

      {wrapJsxIf(
        tableElement,
        !asyncListState.isItemsNotCreated,
        <div
          className={clsx(
            panelStyles.largePanel,
            layoutStyles.limitedContainer,
            'p-24'
          )}
        />
      )}
    </>
  );
}
