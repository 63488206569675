import { ReactNode } from 'react';

import {
  AsyncListItemToRender,
  UseAsyncListProps,
} from '~/shared/components/AsyncList';
import { FunctionButtonProps } from '~/shared/components/FunctionButton';
import {
  TypographyProps,
  TypographyVariants,
} from '~/shared/components/Typography';
import { Falsy, PartialExcept } from '~/shared/types/utility';

/**
 * Possible table display themes
 */
export enum TableThemes {
  primary = 'primary',
  nestedPrimary = 'nestedPrimary',
}

/**
 * Getter for a cell prop prop
 */
export type GetCellProp<Item, PropValue> = (
  item: Item,
  index: number,
  array: Item[]
) => PropValue | Falsy;

/**
 * Config value for a cell prop which can be defined statically or with a function
 */
export type CellPropOrGetCellProp<Item, PropValue> =
  | PropValue
  | GetCellProp<Item, PropValue>;

/**
 * Column config which defines, how a column of data is rendered
 */
export interface TableColumnConfig<
  Item extends object,
  SkeletonItemsCount extends number | undefined = number,
  // Local vars
  ItemToRender extends object = AsyncListItemToRender<Item, SkeletonItemsCount>,
> {
  /**
   * Key for the column
   */
  key: React.Key | null;
  /**
   * Title for the column
   */
  title?: ReactNode;
  /**
   * Header cell may render an additional action icon
   */
  functionButtonProps?: Partial<Omit<FunctionButtonProps, 'ref'>>;
  /**
   * className, applied to both column header (th) and column cell (td)
   */
  columnClassName?: string;
  /**
   * className, applied to the th element of the column header
   */
  headerClassName?: string;
  /**
   * Additional typography props for the cell header
   */
  headerTypographyProps?: Partial<TypographyProps>;
  /**
   * className, applied to the td element of the cell, or a getter to calculate it based on an item
   */
  cellClassName?: CellPropOrGetCellProp<ItemToRender, string>;
  /**
   * Additional typography props for the cell content
   */
  cellTypographyProps?: CellPropOrGetCellProp<
    ItemToRender,
    Partial<TypographyProps>
  >;
  /**
   * Tooltip text, displayed, when the cell is hovered
   */
  cellTooltip?: CellPropOrGetCellProp<ItemToRender, string>;
  /**
   * Text for the default title cell attribute
   */
  cellTitle?: CellPropOrGetCellProp<ItemToRender, string>;
  /**
   * Field of the item, that should be displayed in the cell
   */
  itemField?: keyof ItemToRender;
  /**
   * Custom render for the contents of the cell
   */
  renderCellContent?: (
    item: ItemToRender,
    index: number,
    array: ItemToRender[]
  ) => ReactNode;
  /**
   * If a number greater than zero is returned, than the td tag will have colSpan attribute
   * and the specified number of columns in the config will be skipped
   */
  getColSpan?: (item: ItemToRender) => number;
  /**
   * Width of the column
   */
  width?: string | number;
  /**
   * If passed, this config is used as a grouping title and renders nested columns as usual
   * Now we support only one nesting level
   */
  nestedColumns?: TableColumnConfig<Item, SkeletonItemsCount>[];
  /**
   * If true, renders separate cells in header, when using nested columns, even if this column is not nested
   */
  isSplittedHeader?: boolean;
  /**
   * If true, renders a sticky column
   */
  isSticky?: boolean;
}

/**
 * Inner representation of column configs with additional info for more convenient rendering
 */
export interface TableColumnConfigInner<
  Item extends object,
  SkeletonItemsCount extends number | undefined = number,
> extends TableColumnConfig<Item, SkeletonItemsCount> {
  /**
   * If true, the column is rendered inside of the grouping header
   */
  isNested: boolean;
  /**
   * If true, it means, that the column is the left border of the nesting group
   */
  isNestedLeft: boolean;
  /**
   * If true, it means, that the column is the right border of the nesting group
   */
  isNestedRight: boolean;
  /**
   * To allow flat rendering, we reverse order of the
   */
  groupingColumnConfig?: TableColumnConfig<Item, SkeletonItemsCount>;
}

/**
 * Row config can be used as a table item in case we have dynamic columns and static rows,
 * or if we need to additionally map the rows to the unified format
 */
export interface TableRowConfig<Item extends object, CellData = undefined> {
  /**
   * Key for the row
   */
  id: string;
  /**
   * If passed, render cell content is called only for second and other columns,
   * first column is rendered as static content
   */
  firstColumnContent?: ReactNode;
  /**
   * Custom render for the contents of the cell
   */
  renderCellContent?: (cellData: CellData) => ReactNode;
  /**
   * className, applied to the whole row
   */
  rowClassName?: string;
  /**
   * Typography variant, applied to the whole row
   */
  rowTypographyVariant?: TypographyVariants;
  /**
   * If passed, the row can be expanded and the rows are added in the table
   */
  expandableRows?: TableRowConfig<Item, CellData>[];
}

type TableAsyncProps<
  Item extends object,
  SkeletonItemsCount extends number | undefined = number,
> = PartialExcept<
  UseAsyncListProps<Item, SkeletonItemsCount>,
  'noItemsMessage'
>;

/**
 * Props for the Table component
 */
export interface TableProps<
  Item extends object,
  CellData = undefined,
  SkeletonItemsCount extends number | undefined = number,
  // Local vars
  ItemToRender extends object = AsyncListItemToRender<Item, SkeletonItemsCount>,
> extends TableAsyncProps<Item, SkeletonItemsCount> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Table display theme
   */
  theme?: TableThemes;

  /**
   * Array with column configurations
   */
  columnConfigs: TableColumnConfig<Item, SkeletonItemsCount>[];

  /**
   *  Array of rows for the table
   */
  items: Item[] | undefined;
  /**
   * If passed, renders the given number of skeleton placeholders, while loading initial items.
   *
   * This is defined as a generic to use the correct item type in callbacks
   */
  skeletonItemsCount?: SkeletonItemsCount;
  /**
   *  Return the key for an item, that is used as a React key and for rows selection
   */
  getItemKey?: (item: ItemToRender, index: number) => string | number;
  /**
   * Getter for an additional className, applied to the whole item row
   */
  getRowClassName?: (item: ItemToRender, index: number) => string | Falsy;
  /**
   * Getter for a typography variant for the whole row
   */
  getRowTypographyVariant?: (
    item: ItemToRender,
    index: number
  ) => TypographyVariants | Falsy;

  /**
   * If true, renders additional column for an expand row icon
   */
  isTableWithExpandableRows?: boolean;
  /**
   * If passed, this prop is used to determine, if an item can be expanded
   */
  hasExpandableRowContent?: (item: ItemToRender) => boolean;
  /**
   * If passed, table is rendered with expandable rows
   */
  getExpandableRows?: (
    item: ItemToRender
  ) => TableRowConfig<ItemToRender, CellData>[] | undefined;
  /**
   * If a row can be expanded, this render prop should return the expanded element
   */
  renderExpandableRowContent?: (item: ItemToRender) => ReactNode;
  /**
   * If true, expandable rows are rendered even when they're collapsed,
   * useful to avoid layout shifts with dynamic column widths, but can cause performance issues (default - true)
   */
  shouldUnmountExpandableRows?: boolean;

  /**
   * Table row can display some actions for an item, that will be displayed on the row hover
   */
  renderItemActions?: (item: ItemToRender) => ReactNode;
  /**
   * className applied to the item actions cell
   */
  itemActionCellClassName?: string | ((item: ItemToRender) => string | Falsy);

  /**
   * If true, renders an additional column with checkboxes and allow to select table rows.
   * If passed a predicate, renders a checkbox column and allows to select only items passing the condition
   */
  isSelectable?: boolean | ((item: ItemToRender) => boolean);
  /**
   * Render prop for an action bar, that is rendered instead of table header,
   * to perform some actions on selected items.
   *
   * By default, it is wrapped in a flex container with a gap,
   * so you can just return a fragment with available function buttons as the render result.
   */
  renderActionBar?: (
    selectedItems: Item[],
    renderOptions: {
      isSingleItemSelected: boolean;
      commonFunctionButtonProps: Partial<FunctionButtonProps>;
    }
  ) => ReactNode;

  /**
   * Table header, that is shown only when printing
   */
  printableTitle?: ReactNode;

  /**
   * If true, renders a default border around table, otherwise box-shadow border is used in the custom scroll container
   */
  withBorder?: boolean;
  /**
   * If true, renders a table with wrapped in useCustomScrollWrapper (default - true)
   */
  withCustomScroll?: boolean;

  /**
   * If true, when there is no items in the table and no active search,
   * noItemsMessage is shown not inside table, but as an unwrapped block
   */
  shouldNoItemsMessageHideTable?: boolean;
  /**
   * If true, doesn't render table scrollbars, when table is not overflown (default - true)
   */
  shouldHideScrollBarsForNoOverflow?: boolean;
  /**
   * If true, prevents immediate render of all the rows or columns, when there're to many of them
   * and renders a caption with a button to render all the content
   */
  shouldHideLargeData?: boolean;
}
