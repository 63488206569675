import React from 'react';

import clsx from 'clsx';

import { Badge } from '~/shared/components/Badge';
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { Form } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { OperationAsyncSelect } from '~/entities/operations';

import panelStyles from '~/styles/modules/panel.module.scss';

import { CowsCopyKeyFragment } from '../../../../gql/fragments/cowsCopyKey.graphql';
import { OperationMappingsFormType } from '../../hooks/useOperationMappingsForm';
import stepsStyles from '../../steps.module.scss';
import { MappingType } from '../../types';
import { MappingItem } from '../MappingItem';

interface Props
  extends Pick<
    UseModalStepFormInterface<OperationMappingsFormType>,
    'formProps'
  > {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * operations from CowsCopyKeyFragment
   */
  operations: CowsCopyKeyFragment['operations'];
}

export const OperationMappingsForm: React.FC<Props> = ({
  className,
  formProps,
  operations,
}) => {
  const { formContext } = formProps;
  const operationMappings = formContext.watch('operationMappings');

  const hasOperations = !!operations.length;

  return (
    <Form
      {...{
        className,
        ...formProps,
      }}
    >
      <Typography
        {...{
          tag: 'div',
          variant: TypographyVariants.bodyMediumStrong,
          className: 'mb-16',
        }}
      >
        Переданные пользовательские ветеринарные действия
      </Typography>
      {hasOperations && (
        <>
          <Typography
            {...{
              tag: 'div',
              className: stepsStyles.descriptionBlock,
              variant: TypographyVariants.bodySmall,
            }}
          >
            Проверьте список переданных ветеринарных действий на соответствие
            названиям действий, используемых на вашей ферме
          </Typography>
          <div className="flex gap-8 mb-12">
            <Typography variant={TypographyVariants.bodySmallStrong}>
              Список действий
            </Typography>
            <Badge isPill>{operations?.length ?? 0}</Badge>
            <Button
              {...{
                variant: ButtonVariants.ghost,
                theme: ButtonThemes.neutral,
                iconVariant: IconVariants.block,
                size: ButtonSizes.small24,
                onPress: () => {
                  formContext.setValue(
                    'operationMappings',
                    operationMappings.map(item => ({
                      ...item,
                      mappingType: MappingType.ignore,
                    }))
                  );
                },
              }}
            >
              Не добавлять
            </Button>
          </div>
          <div className="flex flex-col gap-12">
            {operations?.map(({ name }, index) => {
              const operationMapping = operationMappings[index];
              const operationItemNamePrefix =
                `operationMappings.${index}` as const;

              return (
                <MappingItem
                  key={operationMapping.originID}
                  {...{
                    index: index + 1,
                    title: name,
                    targetValue: operationMapping,
                    mappingTypeNamePrefix: operationItemNamePrefix,
                  }}
                >
                  <OperationAsyncSelect
                    {...{
                      name: `${operationItemNamePrefix}.targetID`,
                      label: null,
                    }}
                  />
                </MappingItem>
              );
            })}
          </div>
        </>
      )}
      {!hasOperations && (
        <div className={clsx(panelStyles.largePanel, 'p-24')}>
          <DataBlockedMessage
            {...{
              className: 'p-24',
              message:
                'Информация о пользовательских ветеринарных действиях не была передана',
            }}
          />
        </div>
      )}
    </Form>
  );
};
