import { makeReadFragment } from '~/services/gql';

import {
  OperationFragment,
  OperationFragmentDoc,
} from './gql/fragments/operation.graphql';

/**
 * Reads operation fragment from cache by id
 */
export const readOperationFragment = makeReadFragment<OperationFragment>({
  typeName: 'Operation',
  fragment: OperationFragmentDoc,
});
