import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { OperationFragment } from '../gql/fragments/operation.graphql';
import {
  OperationsQuery,
  OperationsQueryVariables,
  useOperationsQuery,
} from '../gql/queries/operations.graphql';

/**
 * Hook for using operations query with pagination
 */
export const useOperationsPaginatedQuery = makeUsePaginatedQuery<
  OperationFragment,
  OperationsQuery,
  OperationsQueryVariables
>({
  useQuery: useOperationsQuery,
  getItemsFromQueryData: data => data.operations.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.operations.pageInfo,
});
