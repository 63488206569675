import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { VeterinaryActivityFragmentDoc } from '../../gql/fragments/veterinaryActivity.graphql';
import { useVeterinaryActivitiesQuery } from '../../gql/queries/veterinaryActivities.graphql';

/**
 * Select hook for a veterinaryActivity
 */
export const useVeterinaryActivitySelect = makeUseAsyncSelect({
  typeName: 'VeterinaryActivity',
  fragment: VeterinaryActivityFragmentDoc,
  useQuery: useVeterinaryActivitiesQuery,
  getItemsFromQueryData: data =>
    data.veterinaryActivities.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.veterinaryActivities.pageInfo,

  selectProps: {
    label: 'Ветеринарная активность',
    placeholder: 'Выберите активность',
    getItemValue: item => item.id,
    getItemText: item => item?.name ?? '',
  },
});

/**
 * Select for a veterinaryActivity
 */
export const VeterinaryActivityAsyncSelect = makeAsyncSelectFormComponent(
  useVeterinaryActivitySelect
);
