import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { DiseaseFragmentDoc } from '../fragments/disease.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DiseasesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type DiseasesQuery = { __typename?: 'Query', diseases: { __typename?: 'DiseaseConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'DiseaseEdge', cursor: string, node: { __typename: 'Disease', id: string, name: string, isDefault: boolean, shouldSpecifyLimb: boolean } }> } };


export const DiseasesDocument = gql`
    query diseases($after: String, $first: Int, $search: String, $ids: [ID!]) {
  diseases(after: $after, first: $first, search: $search, ids: $ids) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...Disease
      }
    }
  }
}
    ${DiseaseFragmentDoc}`;

/**
 * __useDiseasesQuery__
 *
 * To run a query within a React component, call `useDiseasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiseasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiseasesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDiseasesQuery(baseOptions?: Apollo.QueryHookOptions<DiseasesQuery, DiseasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiseasesQuery, DiseasesQueryVariables>(DiseasesDocument, options);
      }
export function useDiseasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiseasesQuery, DiseasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiseasesQuery, DiseasesQueryVariables>(DiseasesDocument, options);
        }
export function useDiseasesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DiseasesQuery, DiseasesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DiseasesQuery, DiseasesQueryVariables>(DiseasesDocument, options);
        }
export type DiseasesQueryHookResult = ReturnType<typeof useDiseasesQuery>;
export type DiseasesLazyQueryHookResult = ReturnType<typeof useDiseasesLazyQuery>;
export type DiseasesSuspenseQueryHookResult = ReturnType<typeof useDiseasesSuspenseQuery>;
export type DiseasesQueryResult = Apollo.QueryResult<DiseasesQuery, DiseasesQueryVariables>;