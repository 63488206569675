import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { DiseaseFragmentDoc } from '../../../../entities/diseases/gql/fragments/disease.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DiseasesForCowsMovingQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  cowIDs: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type DiseasesForCowsMovingQuery = { __typename?: 'Query', diseasesForCowsMoving: { __typename?: 'DiseaseConnection', nodes: Array<{ __typename: 'Disease', id: string, name: string, isDefault: boolean, shouldSpecifyLimb: boolean }> } };


export const DiseasesForCowsMovingDocument = gql`
    query diseasesForCowsMoving($search: String, $ids: [ID!], $cowIDs: [ID!]!) {
  diseasesForCowsMoving(search: $search, ids: $ids, cowIDs: $cowIDs) {
    nodes {
      ...Disease
    }
  }
}
    ${DiseaseFragmentDoc}`;

/**
 * __useDiseasesForCowsMovingQuery__
 *
 * To run a query within a React component, call `useDiseasesForCowsMovingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiseasesForCowsMovingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiseasesForCowsMovingQuery({
 *   variables: {
 *      search: // value for 'search'
 *      ids: // value for 'ids'
 *      cowIDs: // value for 'cowIDs'
 *   },
 * });
 */
export function useDiseasesForCowsMovingQuery(baseOptions: Apollo.QueryHookOptions<DiseasesForCowsMovingQuery, DiseasesForCowsMovingQueryVariables> & ({ variables: DiseasesForCowsMovingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiseasesForCowsMovingQuery, DiseasesForCowsMovingQueryVariables>(DiseasesForCowsMovingDocument, options);
      }
export function useDiseasesForCowsMovingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiseasesForCowsMovingQuery, DiseasesForCowsMovingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiseasesForCowsMovingQuery, DiseasesForCowsMovingQueryVariables>(DiseasesForCowsMovingDocument, options);
        }
export function useDiseasesForCowsMovingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DiseasesForCowsMovingQuery, DiseasesForCowsMovingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DiseasesForCowsMovingQuery, DiseasesForCowsMovingQueryVariables>(DiseasesForCowsMovingDocument, options);
        }
export type DiseasesForCowsMovingQueryHookResult = ReturnType<typeof useDiseasesForCowsMovingQuery>;
export type DiseasesForCowsMovingLazyQueryHookResult = ReturnType<typeof useDiseasesForCowsMovingLazyQuery>;
export type DiseasesForCowsMovingSuspenseQueryHookResult = ReturnType<typeof useDiseasesForCowsMovingSuspenseQuery>;
export type DiseasesForCowsMovingQueryResult = Apollo.QueryResult<DiseasesForCowsMovingQuery, DiseasesForCowsMovingQueryVariables>;