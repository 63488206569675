import React, { useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import R from 'ramda';

import { getAsyncListState, useAsyncList } from '~/shared/components/AsyncList';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { Skeleton } from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeRefs } from '~/shared/helpers/mergeProps';
import { useCustomScrollWrapper } from '~/shared/hooks/useCustomScrollWrapper';
import { usePrevious } from '~/shared/hooks/usePrevious';

import panelStyles from '~/styles/modules/panel.module.scss';

import { HiddenDataCaption } from './components/HiddenDataCaption';
import { TableHeader } from './components/TableHeader';
import { TableRow } from './components/TableRow';
import { MIN_ENTRIES_COUNT_TO_HIDE } from './constants';
import {
  defaultGetItemKey,
  getShouldHideLargeEntries,
  getSpanValue,
} from './helpers';
import styles from './index.module.scss';
import { TableProps, TableThemes } from './types';

const TableInternal = <
  Item extends object,
  CellData = undefined,
  SkeletonItemsCount extends number | undefined = number,
>(
  props: TableProps<Item, CellData, SkeletonItemsCount>,
  ref: React.Ref<HTMLDivElement>
) => {
  const {
    className,
    theme = TableThemes.primary,

    columnConfigs,

    items: itemsProp = [],
    skeletonItemsCount,
    getItemKey = defaultGetItemKey,

    isTableWithExpandableRows: isTableWithExpandableRowsProp = false,
    getExpandableRows,
    renderExpandableRowContent,
    hasExpandableRowContent = R.always(true),

    renderItemActions,

    isSelectable = false,

    withCustomScroll = true,
    withBorder = false,

    shouldNoItemsMessageHideTable = false,
    shouldHideScrollBarsForNoOverflow = true,
    shouldHideLargeData: shouldHideLargeDataProp = false,

    noItemsMessage,
    noSearchItemsMessage,
    noSearchItemsDescription,

    filtersElement,

    ...asyncListProps
  } = props;

  const cellTypographyVariant =
    theme === TableThemes.primary
      ? TypographyVariants.bodySmall
      : TypographyVariants.descriptionLarge;

  // Prepare column configs for rendering and create a flat structure with additional info

  const flattenColumnConfigsFull = useMemo(() => {
    return columnConfigs.flatMap(config => {
      if (config.nestedColumns) {
        return config.nestedColumns.map(
          (nestedConfig, configIndex, configsArray) => {
            const isNestedLeft = !configIndex;
            const isNestedRight = configIndex === configsArray.length - 1;
            return {
              ...nestedConfig,
              isNested: true,
              isNestedLeft,
              isNestedRight,
              groupingColumnConfig: config,
            };
          }
        );
      }
      return [
        {
          ...config,
          isNested: false,
          isNestedLeft: false,
          isNestedRight: false,
        },
      ];
    });
  }, [columnConfigs]);

  // Apply large data hiding for better performance until explicit user choice to show it

  const shouldHideLargeDataColumns = getShouldHideLargeEntries(
    flattenColumnConfigsFull.length
  );
  const shouldHideLargeDataRows = getShouldHideLargeEntries(itemsProp.length);

  const shouldHideLargeData =
    shouldHideLargeDataProp &&
    (shouldHideLargeDataColumns || shouldHideLargeDataRows);

  const [isLargeDataHiddenState, setIsLargeDataHiddenState] =
    useState(shouldHideLargeData);

  // Reset shouldHideLargeData, when column configs change
  const prevColumnConfigsFull = usePrevious(flattenColumnConfigsFull);
  useEffect(() => {
    if (prevColumnConfigsFull !== flattenColumnConfigsFull) {
      setIsLargeDataHiddenState(shouldHideLargeData);
    }
  }, [flattenColumnConfigsFull]);

  const isLargeDataHidden =
    prevColumnConfigsFull === flattenColumnConfigsFull
      ? isLargeDataHiddenState
      : shouldHideLargeData;

  const flattenColumnConfigs = useMemo(() => {
    return isLargeDataHidden
      ? flattenColumnConfigsFull.slice(0, MIN_ENTRIES_COUNT_TO_HIDE)
      : flattenColumnConfigsFull;
  }, [isLargeDataHidden, flattenColumnConfigsFull]);

  const items = useMemo(() => {
    return isLargeDataHidden
      ? itemsProp.slice(0, MIN_ENTRIES_COUNT_TO_HIDE)
      : itemsProp;
  }, [isLargeDataHidden, itemsProp]);

  // Calculate table dimensions

  const expandableRowsCount = items.filter(hasExpandableRowContent).length;

  const isTableWithExpandableRows =
    isTableWithExpandableRowsProp ||
    (!!getExpandableRows && !!expandableRowsCount) ||
    !!renderExpandableRowContent;

  const columnsCount =
    (isTableWithExpandableRows ? 1 : 0) +
    (isSelectable ? 1 : 0) +
    flattenColumnConfigs.length +
    (renderItemActions ? 1 : 0);

  const { useScrollMeasureRef, renderCustomScrollWrapper } =
    useCustomScrollWrapper({
      shouldHideScrollBarsForNoOverflow,
    });

  const asyncListState = getAsyncListState<Item, SkeletonItemsCount>({
    items,
    skeletonItemsCount,
    isLoading: asyncListProps?.isLoading,
  });

  const {
    isSkeletonLoading,
    loaderElement,

    noItemsMessageElement,

    isItemsNotCreated,

    itemsToRender,
  } = useAsyncList<Item, HTMLTableSectionElement, SkeletonItemsCount>({
    asyncListState,
    renderNoItemsMessage: (message, currentIsItemsNotCreated) =>
      shouldNoItemsMessageHideTable && currentIsItemsNotCreated ? (
        message
      ) : (
        <tr className={styles.row}>
          <td
            {...{
              className: styles.cell,
              colSpan: columnsCount,
              style: {
                gridColumn: getSpanValue(columnsCount),
              },
            }}
          >
            <Typography
              className="col-span-full"
              variant={cellTypographyVariant}
            >
              {message}
            </Typography>
          </td>
        </tr>
      ),
    ...asyncListProps,
    noItemsMessage: shouldNoItemsMessageHideTable ? (
      noItemsMessage
    ) : (
      <DataBlockedMessage
        className={styles.blockedMessage}
        message={noItemsMessage}
      />
    ),
    noSearchItemsMessage: (
      <DataBlockedMessage
        {...{
          iconVariant: IconVariants.search,
          className: styles.blockedMessage,
          message: noSearchItemsMessage,
          description: noSearchItemsDescription,
        }}
      />
    ),
    renderLoader: sentryRef => (
      <tr className={styles.row}>
        <td
          {...{
            className: styles.cell,
            colSpan: columnsCount,
            style: {
              gridColumn: getSpanValue(columnsCount),
            },
          }}
        >
          <Typography className="col-span-full" variant={cellTypographyVariant}>
            <DataBlockedMessage
              {...{
                className: styles.blockedMessage,
                isLoading: true,
                loaderRef: sentryRef,
                message: 'Загружаем данные таблицы',
              }}
            />
          </Typography>
        </td>
      </tr>
    ),
  });

  const [selectedItemKeys, setSelectedItemKeys] = useState<(string | number)[]>(
    []
  );
  const selectedItems = useMemo(
    () =>
      items.filter((rowItem, rowItemIndex) =>
        selectedItemKeys.includes(getItemKey(rowItem, rowItemIndex))
      ),
    [selectedItemKeys]
  );

  if (shouldNoItemsMessageHideTable && isItemsNotCreated) {
    return noItemsMessageElement;
  }

  // We need to have an explicit grid to allow negative grid line numbers for hidden data caption
  // https://www.w3.org/TR/css3-grid-layout/#explicit-grids
  const gridTemplateRows = `repeat(${items.length + expandableRowsCount}, auto)`;
  // Additional render counter for explicit gridRow
  let expandableRowsRendered = 0;

  const hiddenRowsCount = itemsProp.length - items.length;

  const asyncListTableBodyElement = (
    <tbody
      {...{
        className: styles.tbody,
        style: {
          gridTemplateRows,
        },
      }}
    >
      {noItemsMessageElement}

      {itemsToRender.map((rowItem, rowItemIndex, rowsArray) => {
        if (rowItemIndex === 0) {
          expandableRowsRendered = 0;
        }
        const isExpandableRow =
          isTableWithExpandableRows && hasExpandableRowContent(rowItem);

        const itemKey = getItemKey(rowItem, rowItemIndex);

        const rowElement = (
          <TableRow<Item, CellData, SkeletonItemsCount>
            key={itemKey}
            {...{
              rowItem,
              rowItemIndex,
              rowsArray,
              gridRow: rowItemIndex + 1 + expandableRowsRendered,

              isSelected: selectedItemKeys.includes(itemKey),
              onSelectedChange: newIsSelected => {
                setSelectedItemKeys(currentSelectedItemKeys =>
                  newIsSelected
                    ? [...currentSelectedItemKeys, itemKey]
                    : currentSelectedItemKeys.filter(k => k !== itemKey)
                );
              },

              isTableWithExpandableRows,
              isExpandableRow,

              flattenColumnConfigs,

              cellTypographyVariant,

              tableProps: props,
            }}
          />
        );

        if (isExpandableRow) {
          expandableRowsRendered += 1;
        }

        return rowElement;
      })}

      {loaderElement}

      {isLargeDataHidden && shouldHideLargeDataRows && (
        <HiddenDataCaption
          {...{
            isRows: true,
            isColumnsHidden: shouldHideLargeDataColumns,
            itemsCount: items.length,
            hiddenItemsCount: hiddenRowsCount,
            onShowAll: () => setIsLargeDataHiddenState(false),
          }}
        />
      )}
    </tbody>
  );

  const expandableRowColumnTrack = isTableWithExpandableRows
    ? 'var(--table-function-cell-width)'
    : '';
  const selectableRowColumnTrack = isSelectable
    ? 'var(--table-function-cell-width)'
    : '';
  const isAllColumnWidthsDefinedWithNumbers = !flattenColumnConfigs.some(
    c => typeof c.width !== 'number'
  );
  const otherColumnTracks = flattenColumnConfigs.map(c => {
    if (typeof c.width === 'number') {
      return isAllColumnWidthsDefinedWithNumbers
        ? `minmax(${c.width}px, ${c.width}fr)`
        : `${c.width}px`;
    }
    return c.width ?? 'auto';
  });
  const itemActionsColumnTrack = renderItemActions
    ? 'calc(var(--size-24) + var(--table-cell-inline-padding) * 2)'
    : '';

  const gridTemplateColumns = [
    expandableRowColumnTrack,
    selectableRowColumnTrack,
    ...otherColumnTracks,
    itemActionsColumnTrack,
  ].join(' ');

  const hiddenColumnsCount =
    flattenColumnConfigsFull.length - flattenColumnConfigs.length;

  const selectableItems = useMemo(() => {
    if (typeof isSelectable === 'function') {
      return items?.filter(isSelectable);
    }
    return items;
  }, [items]);

  const tableElement = (
    <table
      {...{
        ref: mergeRefs(useScrollMeasureRef, ref),
        className: clsx(
          styles.root,
          className,
          styles[theme],
          withBorder && styles.withBorder,
          isSelectable && styles.selectable
        ),
        style: {
          gridTemplateColumns,
        },
      }}
    >
      {isLargeDataHidden && shouldHideLargeDataColumns && (
        <HiddenDataCaption
          {...{
            isRows: false,
            hiddenItemsCount: hiddenColumnsCount,
            onShowAll: () => setIsLargeDataHiddenState(false),
          }}
        />
      )}
      <Skeleton withDelay isLoading={isSkeletonLoading}>
        <TableHeader
          {...{
            tableProps: props,

            isTableWithExpandableRows,
            columnsCount,
            flattenColumnConfigs,

            selectableItems,
            selectedItems,
            onSelectAll: isAllItemsSelected => {
              setSelectedItemKeys(
                isAllItemsSelected ? selectableItems.map(getItemKey) : []
              );
            },
          }}
        />
        {asyncListTableBodyElement}
      </Skeleton>
    </table>
  );

  const tableWithScrollElement = withCustomScroll
    ? renderCustomScrollWrapper({
        className: styles.scrollWrapper,
        scrollBarKey: columnConfigs.length,
        contentClassName: clsx(
          panelStyles.panel,
          'shadow-border',
          styles.scrollContainer
        ),
        children: tableElement,
      })
    : tableElement;

  return (
    <>
      {!isItemsNotCreated && filtersElement}
      {tableWithScrollElement}
    </>
  );
};

export * from './types';
export * from './constants';

// Workaround for typing generic HOC
type RenderTable = <
  Item extends object,
  CellData = undefined,
  SkeletonItemsCount extends number | undefined = number,
>(
  props: TableProps<Item, CellData, SkeletonItemsCount>
) => React.ReactElement;

export const Table = React.forwardRef<HTMLTableElement, TableProps<any, any>>(
  TableInternal
) as RenderTable;
