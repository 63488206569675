import { createFileRoute } from '@tanstack/react-router';

import { getAsyncListState } from '~/shared/components/AsyncList';
import {
  FunctionButton,
  FunctionButtonVariants,
} from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { isSkeletonPlaceholder } from '~/shared/components/Skeleton';
import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableColumnConfig,
} from '~/shared/components/Table';
import { TABLE_ROWS_DEFAULT_SKELETON_COUNT } from '~/shared/constants';
import { renderOrMdash } from '~/shared/helpers/render';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';
import {
  ExtractSearchParamsFromRoute,
  useSearchParamsState,
} from '~/shared/hooks/useSearchParamsState';

import {
  SearchableSearchParams,
  WithSearchParamsValidation,
} from '~/services/navigation';

import {
  PROCESS_KINDS_DICT,
  useProtocolsPaginatedQuery,
} from '~/entities/protocols';
import { ProtocolFragment } from '~/entities/protocols/gql/fragments/protocol.graphql';

const NAME_COLUMN_WIDTH_PX = 300;
const PROCESS_KIND_COLUMN_WIDTH_PX = 168;
const AVAILABILITY_COLUMN_WIDTH_PX = 208;

export const Route = createFileRoute(
  '/$companyId/_layout/user/events/all/protocols'
)({
  wrapInSuspense: true,
  component: ProtocolsPage,
  validateSearch: ({
    search,
  }: WithSearchParamsValidation<SearchableSearchParams>) => ({
    search: search ?? '',
  }),
});

function ProtocolsPage() {
  const { search: debouncedSearch } =
    useSearchParamsState<ExtractSearchParamsFromRoute<typeof Route>>();

  const { isSearchActive } = useDebouncedSearch({
    search: debouncedSearch,
  });

  const queryVariables = {
    search: debouncedSearch,
  };

  const { items, ...asyncProps } = useProtocolsPaginatedQuery({
    variables: queryVariables,
  });

  const columnConfigs: TableColumnConfig<ProtocolFragment>[] = [
    {
      title: 'Название активности',
      key: 'name',
      itemField: 'name',
      width: NAME_COLUMN_WIDTH_PX,
    },
    {
      title: 'Тип протокола',
      key: 'processKind',
      renderCellContent: item =>
        isSkeletonPlaceholder(item) ? '' : PROCESS_KINDS_DICT[item.processKind],
      width: PROCESS_KIND_COLUMN_WIDTH_PX,
    },
    {
      title: 'Доступность протокола',
      key: 'availability',
      renderCellContent: item =>
        renderOrMdash(
          [item.forYoung && 'Для молодняка', item.forCows && 'Для коров'].join(
            ', '
          )
        ),
      width: AVAILABILITY_COLUMN_WIDTH_PX,
    },
    FILLER_COLUMN_CONFIG,
  ];

  const asyncListState = getAsyncListState({
    items,
    skeletonItemsCount: TABLE_ROWS_DEFAULT_SKELETON_COUNT,
    isSearchActive,
    isLoading: asyncProps.isLoading,
  });

  return (
    <Table<ProtocolFragment>
      {...{
        asyncListState,
        className: 'min-w-full w-min',
        items,
        columnConfigs,
        noItemsMessage: 'Протоколы пока не добавлены',
        noSearchItemsMessage: 'Протоколы не найдены',
        noSearchItemsDescription:
          'По вашему запросу нет подходящих протоколов. Используйте другое название',
        isSearchActive,
        isSelectable: true,
        renderActionBar: (
          selectedItems,
          { isSingleItemSelected, commonFunctionButtonProps }
        ) => {
          const isAllActivated = !selectedItems.some(
            protocol => !!protocol.deactivatedAt
          );
          const isAllDeactivated = !selectedItems.some(
            protocol => !protocol.deactivatedAt
          );

          const activationButtonElement = isAllDeactivated ? (
            <FunctionButton
              {...{
                iconVariant: IconVariants.checkCircle,
                children: 'Активировать',
                onPress: () => {},
                ...commonFunctionButtonProps,
              }}
            />
          ) : (
            <FunctionButton
              {...{
                iconVariant: IconVariants.checkCircle,
                isDisabled: !isAllActivated,
                children: 'Деактивировать',
                onPress: () => {},
                ...commonFunctionButtonProps,
              }}
            />
          );

          return (
            <>
              <FunctionButton
                {...{
                  iconVariant: IconVariants.edit,
                  isDisabled: !isSingleItemSelected,
                  children: 'Редактировать',
                  onPress: () => {},
                  ...commonFunctionButtonProps,
                }}
              />
              <FunctionButton
                {...{
                  iconVariant: IconVariants.duplicate,
                  children: 'Копировать активность',
                  onPress: () => {},
                  ...commonFunctionButtonProps,
                }}
              />

              {activationButtonElement}

              <FunctionButton
                {...{
                  iconVariant: IconVariants.delete,
                  variant: FunctionButtonVariants.failure,
                  isDisabled: !isSingleItemSelected,
                  children: 'Удалить',
                  onPress: () => {},
                  ...commonFunctionButtonProps,
                }}
              />
            </>
          );
        },
        ...asyncProps,
      }}
    />
  );
}
