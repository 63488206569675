import { makeReadFragment } from '~/services/gql';

import {
  DiseaseFragment,
  DiseaseFragmentDoc,
} from './gql/fragments/disease.graphql';

/**
 * Reads disease fragment from cache by id
 */
export const readDiseaseFragment = makeReadFragment<DiseaseFragment>({
  typeName: 'Disease',
  fragment: DiseaseFragmentDoc,
});
