import { createFileRoute } from '@tanstack/react-router';

import { getAsyncListState } from '~/shared/components/AsyncList';
import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableColumnConfig,
} from '~/shared/components/Table';
import { TABLE_ROWS_DEFAULT_SKELETON_COUNT } from '~/shared/constants';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';
import {
  ExtractSearchParamsFromRoute,
  useSearchParamsState,
} from '~/shared/hooks/useSearchParamsState';

import {
  SearchableSearchParams,
  WithSearchParamsValidation,
} from '~/services/navigation';

import { useDefaultVeterinaryActivitiesPaginatedQuery } from '~/entities/defaultVeterinaryActivities';
import { DefaultVeterinaryActivityFragment } from '~/entities/defaultVeterinaryActivities/gql/fragments/defaultVeterinaryActivity.graphql';

const NAME_COLUMN_WIDTH_PX = 300;
const IS_DEFAULT_COLUMN_WIDTH_PX = 184;

export const Route = createFileRoute(
  '/$companyId/_layout/user/events/all/common'
)({
  wrapInSuspense: true,
  component: DefaultVeterinaryActivitiesPage,
  validateSearch: ({
    search,
  }: WithSearchParamsValidation<SearchableSearchParams>) => ({
    search: search ?? '',
  }),
});

function DefaultVeterinaryActivitiesPage() {
  const { search: debouncedSearch } =
    useSearchParamsState<ExtractSearchParamsFromRoute<typeof Route>>();

  const { isSearchActive } = useDebouncedSearch({
    search: debouncedSearch,
  });

  const queryVariables = {
    search: debouncedSearch,
  };

  const { items, ...asyncProps } = useDefaultVeterinaryActivitiesPaginatedQuery(
    {
      variables: queryVariables,
    }
  );

  const columnConfigs: TableColumnConfig<DefaultVeterinaryActivityFragment>[] =
    [
      {
        title: 'Название активности',
        key: 'name',
        itemField: 'name',
        width: NAME_COLUMN_WIDTH_PX,
      },
      {
        title: 'Тип',
        key: 'isDefault',
        renderCellContent: item =>
          item.isDefault ? 'Стандартное' : 'Пользовательское',
        width: IS_DEFAULT_COLUMN_WIDTH_PX,
      },
      FILLER_COLUMN_CONFIG,
    ];

  const asyncListState = getAsyncListState({
    items,
    skeletonItemsCount: TABLE_ROWS_DEFAULT_SKELETON_COUNT,
    isSearchActive,
    isLoading: asyncProps.isLoading,
  });

  return (
    <Table<DefaultVeterinaryActivityFragment>
      {...{
        asyncListState,
        className: 'min-w-full w-min',
        items,
        columnConfigs,
        noItemsMessage: 'Активности пока не добавлены',
        noSearchItemsMessage: 'Активности не найдены',
        noSearchItemsDescription:
          'По вашему запросу нет подходящих активностей. Используйте другое название',
        isSearchActive,
        ...asyncProps,
      }}
    />
  );
}
