import React from 'react';

import clsx from 'clsx';
import R from 'ramda';

import {
  getSkeletonPlaceholders,
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
  TextSkeletonSizes,
} from '~/shared/components/Skeleton';
import { FILLER_COLUMN_CONFIG, Table } from '~/shared/components/Table';
import { TextLink } from '~/shared/components/TextLink';
import { Tooltip } from '~/shared/components/Tooltip';
import { TypographyVariants } from '~/shared/components/Typography';

import { formatSourceFieldValue } from '~/entities/customReports';

import {
  CUSTOM_REPORT_VALUE_FORMULA_KIND_DICT,
  isCustomReportHeaderColumn,
} from '~/features/customReportLaunch';
import { CustomReportPivotTableFragment } from '~/features/customReportLaunch/gql/fragments/customReportPivotTable.graphql';

import { PERCENT_FORMATTING_VIEW_KINDS } from '../../constants';
import {
  getCustomReportLaunchDetailFilters,
  isCustomReportColumn,
  isCustomReportFormulaColumn,
  isCustomReportNestedRow,
  isTotalSourceFieldValue,
} from '../../helpers';
import {
  AnyCustomReportRow,
  CustomReportPivotCellData,
  CustomReportPivotSettingsFormType,
} from '../../types';
import { formatCustomReportRowValue } from './helpers';
import {
  CustomReportPivotColumnConfig,
  CustomReportPivotRowConfig,
} from './types';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If false, shows text links to detailed results
   */
  isEditing?: boolean;
  /**
   * Custom report to take settings from
   */
  requestedSettings: CustomReportPivotSettingsFormType;
  /**
   * Pivot data to render
   */
  pivotData: CustomReportPivotTableFragment | SkeletonPlaceholder;
}

const SKELETON_COLUMNS_COUNT = 12;
const SKELETON_ROWS_COUNT = 8;

const NESTED_COLUMN_WIDTH_PX = 76;

const MAX_COLUMN_CONTENT_WIDTH =
  'calc(170px - var(--table-cell-inline-padding) * 2)';
const MAX_COLUMN_WIDTH = `minmax(max-content, 1fr)`;

const CELL_TOOLTIP_MAX_WIDTH_PX = 264;

export const CustomReportPivotTable: React.FC<Props> = ({
  className,
  requestedSettings,
  isEditing = false,
  pivotData,
}) => {
  const isEmptyRowsSettings = !requestedSettings.rows.length;
  const isEmptyColumnsSettings = !requestedSettings.columns.length;
  const isEmptyValuesSettings = !requestedSettings.values.length;

  const columns =
    pivotData.columns ?? getSkeletonPlaceholders(SKELETON_COLUMNS_COUNT);

  // We need to subtract number of source field columns from value indices,
  // cause they're not counted in the row values array.
  // Type cast to any due to https://github.com/total-typescript/ts-reset/issues/168
  const sourceFieldColumnsCount = columns.filter(
    isCustomReportColumn as any
  ).length;

  const columnConfigs: CustomReportPivotColumnConfig[] =
    columns.map<CustomReportPivotColumnConfig>(
      (pivotColumn, pivotColumnIndex) => {
        const baseCellData = { pivotColumn, pivotColumnIndex };

        const columnKey = `${pivotColumn.blueprintSourceField?.id}__${
          isCustomReportFormulaColumn(pivotColumn) ? pivotColumn.formula : ''
        }__${pivotColumnIndex}`;

        const columnSourceFieldName =
          pivotColumn.blueprintSourceField?.name ?? '';

        const columnTitle = isCustomReportFormulaColumn(pivotColumn)
          ? `${
              CUSTOM_REPORT_VALUE_FORMULA_KIND_DICT[pivotColumn.formula]
            } из ${columnSourceFieldName}`
          : columnSourceFieldName;

        const config: CustomReportPivotColumnConfig = {
          key: columnKey,
          title: columnTitle,
          columnClassName:
            pivotColumnIndex === 0 || isCustomReportHeaderColumn(pivotColumn)
              ? undefined
              : 'text-right',
          cellTypographyProps: row => ({
            variant: row.rowTypographyVariant,
            skeletonProps: {
              size:
                pivotColumnIndex === 0
                  ? TextSkeletonSizes.medium
                  : TextSkeletonSizes.small,
            },
          }),
        };

        const headerTypographyProps = {
          style: {
            maxWidth: MAX_COLUMN_CONTENT_WIDTH,
          },
        };

        if (isCustomReportHeaderColumn(pivotColumn)) {
          config.nestedColumns = pivotColumn.blueprintSourceFieldValues.map(
            (sourceFieldValue, pivotNestedColumnIndex) => ({
              key: `${columnKey}__${pivotNestedColumnIndex}`,
              title: isTotalSourceFieldValue(sourceFieldValue)
                ? 'Итого'
                : formatSourceFieldValue(sourceFieldValue),
              renderCellContent: row =>
                row.renderCellContent?.({
                  ...baseCellData,
                  pivotNestedColumnIndex,
                }),
              columnClassName: 'text-right',
              width: MAX_COLUMN_WIDTH,
              headerTypographyProps,
            })
          );
        } else {
          config.renderCellContent = row =>
            row.renderCellContent?.(baseCellData);
          config.width = MAX_COLUMN_WIDTH;
          config.headerTypographyProps = headerTypographyProps;
        }

        return config;
      }
    );

  const renderValuePlaceholderCell = () => (
    <span className="text-disabled">Значение</span>
  );

  if (!isSkeletonPlaceholder(pivotData)) {
    if (isEmptyRowsSettings) {
      columnConfigs.unshift({
        key: 'placeholderColumnForRow',
        columnClassName: 'text-disabled',
        title: '',
        renderCellContent: R.always('Строка'),
        width: NESTED_COLUMN_WIDTH_PX,
      });
    }

    if (isEmptyColumnsSettings && isEmptyValuesSettings) {
      columnConfigs.push({
        key: 'placeholderColumn',
        columnClassName: 'text-disabled',
        title: 'Столбец',
        renderCellContent: renderValuePlaceholderCell,
      });
    }

    columnConfigs.push(FILLER_COLUMN_CONFIG);
  }

  const makeRenderCellContent =
    ({
      pivotRow,
      pivotNestedRow,
    }: {
      pivotRow: AnyCustomReportRow;
      pivotNestedRow?: AnyCustomReportRow;
    }): CustomReportPivotRowConfig['renderCellContent'] =>
    ({ pivotColumn, pivotColumnIndex, pivotNestedColumnIndex }) => {
      const isNestedRow = !!pivotNestedRow;
      const rowToDisplay = isNestedRow ? pivotNestedRow : pivotRow;
      const columnIndexToSkip = isNestedRow ? 0 : 1;
      if (
        columnIndexToSkip < sourceFieldColumnsCount &&
        pivotColumnIndex === columnIndexToSkip
      ) {
        return '';
      }

      if (isCustomReportColumn(pivotColumn)) {
        return isTotalSourceFieldValue(rowToDisplay.blueprintSourceFieldValue)
          ? 'Всего'
          : formatSourceFieldValue(rowToDisplay.blueprintSourceFieldValue);
      }

      const pivotRowSourceFieldColumn = columns.at(0);
      const pivotNestedRowSourceFieldColumn = columns.at(1);

      const currentValueIndex =
        isCustomReportHeaderColumn(pivotColumn) &&
        !R.isNil(pivotNestedColumnIndex)
          ? pivotNestedColumnIndex
          : pivotColumnIndex - sourceFieldColumnsCount;

      const currentValue = rowToDisplay.values?.[currentValueIndex];

      const shouldFormatNumberWithPercent =
        (!!requestedSettings.columns.length &&
          PERCENT_FORMATTING_VIEW_KINDS.includes(
            requestedSettings.values.at(0)?.view
          )) ||
        (isCustomReportFormulaColumn(pivotColumn) &&
          PERCENT_FORMATTING_VIEW_KINDS.includes(pivotColumn.view));

      if (!currentValue) {
        return isEmptyValuesSettings ? renderValuePlaceholderCell() : '';
      }

      return (
        <Tooltip
          {...{
            content: 'Нажмите, чтобы открыть детали в новой вкладке',
            maxWidth: CELL_TOOLTIP_MAX_WIDTH_PX,
            isDisabled: isEditing,
          }}
        >
          <TextLink
            {...{
              target: '_blank',
              to: '/$companyId/user/analytics/custom-reports/$customReportId/$launchHashId/detail',
              params: true,
              search: pivotRowSourceFieldColumn
                ? getCustomReportLaunchDetailFilters({
                    pivotColumn,
                    pivotColumnIndex,
                    pivotNestedColumnIndex,
                    pivotRow,
                    pivotRowSourceFieldColumn,
                    pivotNestedRow,
                    pivotNestedRowSourceFieldColumn:
                      pivotNestedRowSourceFieldColumn &&
                      isCustomReportColumn(pivotNestedRowSourceFieldColumn)
                        ? pivotNestedRowSourceFieldColumn
                        : undefined,
                  })
                : {},
              isDisabled: isEditing,
            }}
          >
            {formatCustomReportRowValue(
              currentValue,
              shouldFormatNumberWithPercent
            )}
          </TextLink>
        </Tooltip>
      );
    };

  const getRowClassName = (pivotRow: AnyCustomReportRow) =>
    !isSkeletonPlaceholder(pivotRow) &&
    R.isNil(pivotRow.blueprintSourceFieldValue)
      ? 'background-neutral-opaque-container-default'
      : undefined;

  const getRowTypographyVariant = (pivotRow: AnyCustomReportRow) =>
    !isSkeletonPlaceholder(pivotRow) &&
    R.isNil(pivotRow.blueprintSourceFieldValue)
      ? TypographyVariants.bodySmallStrong
      : undefined;

  const rows = pivotData.rows ?? getSkeletonPlaceholders(SKELETON_ROWS_COUNT);
  const rowConfigs: CustomReportPivotRowConfig[] = rows.map(
    (pivotRow, pivotRowIndex) => {
      const config: CustomReportPivotRowConfig = {
        id: pivotRowIndex.toString(),
        renderCellContent: makeRenderCellContent({ pivotRow }),
        rowClassName: getRowClassName(pivotRow),
        rowTypographyVariant: getRowTypographyVariant(pivotRow),
      };

      if (isCustomReportNestedRow(pivotRow)) {
        config.expandableRows = pivotRow.nestedRows.map(
          (pivotNestedRow, pivotNestedRowIndex) => ({
            id: `${pivotRowIndex}__${pivotNestedRowIndex}`,
            renderCellContent: makeRenderCellContent({
              pivotRow,
              pivotNestedRow,
            }),
            rowClassName: getRowClassName(pivotNestedRow),
            rowTypographyVariant: getRowTypographyVariant(pivotNestedRow),
          })
        );
      }

      return config;
    }
  );

  if (isEmptyRowsSettings && isEmptyValuesSettings) {
    rowConfigs.push({
      id: 'placeholderRow',
      renderCellContent: renderValuePlaceholderCell,
    });
  }

  return (
    <Table<CustomReportPivotRowConfig, CustomReportPivotCellData>
      {...{
        className: clsx(className, 'min-w-full w-min'),
        items: rowConfigs,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
        shouldHideScrollBarsForNoOverflow: false,
        getRowClassName: item => item.rowClassName,
        hasExpandableRowContent: item => !!item.expandableRows,
        getExpandableRows: item => item.expandableRows,
        shouldUnmountExpandableRows: false,
        shouldHideLargeData: true,
      }}
    />
  );
};
