import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';

import { useApolloClient } from '@apollo/client';

import clsx from 'clsx';
import R from 'ramda';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useNotifications } from '~/services/notifications';

import { readOperationFragment } from '~/entities/operations/helpers';

import formStyles from '~/styles/modules/form.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { MoveCowCard } from '../../../../components';
import { useOperationsForCowsMovingQuery } from '../../../../gql/queries/operationsForCowsMoving.graphql';
import { useAddOperationIdsToListModal } from '../../../index';
import stepsStyles from '../../steps.module.scss';
import { MoveCowsFormType } from '../../types';
import { ContentItems } from '../ContentItems';

export const ChooseEventsStep: React.FC = () => {
  const { sendNeutralToast } = useNotifications();
  const client = useApolloClient();

  const formContext = useFormContext<MoveCowsFormType>();
  const shouldRefetchOperations =
    !formContext.getFieldState('operationIDs').isDirty;

  const operationIDs = formContext.watch('operationIDs');
  const cowIDs = formContext.watch('cowIDs');

  const selectedOperations = operationIDs
    .map(operationID => readOperationFragment(client, operationID))
    .filter(Boolean);
  const { open: openAddOperationIdsToListModal } =
    useAddOperationIdsToListModal();

  const { data: operationsForCowsMovingQueryData, loading: isLoading } =
    useOperationsForCowsMovingQuery({
      variables: { cowIDs },
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        if (shouldRefetchOperations) {
          formContext.setValue(
            'operationIDs',
            data.operationsForCowsMoving.nodes.map(R.prop('id')),
            {
              shouldDirty: true,
            }
          );
        }
      },
    });
  const availableOperations =
    operationsForCowsMovingQueryData?.operationsForCowsMoving.nodes ?? [];

  return (
    <div
      {...{
        className: clsx(
          panelStyles.largeDarkPanel,
          formStyles.singleColumnForm,
          'p-16'
        ),
      }}
    >
      <Typography variant={TypographyVariants.bodyMediumStrong}>
        Передача ветеринарных действий
      </Typography>
      <Typography
        {...{
          className: stepsStyles.descriptionBlock,
          variant: TypographyVariants.bodySmall,
        }}
      >
        По умолчанию выбраны все ветеринарные действия для передачи на другую
        площадку. Вы можете полностью очистить выбор или удалить по одному.
      </Typography>
      <ContentItems
        {...{
          title: 'Список ветеринарных действий',
          onClear: () => formContext.setValue('operationIDs', []),
          amount: `${operationIDs.length} из ${availableOperations.length}`,
          addButtonProps: {
            onPress: () => {
              openAddOperationIdsToListModal({
                availableOperations,
                defaultValues: operationIDs,
                onSubmit: newOperationIds =>
                  formContext.setValue('operationIDs', newOperationIds, {
                    shouldDirty: true,
                  }),
              });
            },
            children: 'Добавить действия',
          },
          dataBlockedMessageProps: {
            isLoading,
            loadingMessage: 'Загрузка ветеринарных действий',
            message: 'Ветеринарные действия пока не добавлены',
          },
          itemsToRender: selectedOperations.map((operation, index) => (
            <MoveCowCard
              key={operation.id}
              {...{
                contentItems: [
                  <Fragment key={operation.id}>{operation.name}</Fragment>,
                ],
                index: index + 1,
                onDelete: () => {
                  formContext.setValue(
                    'operationIDs',
                    operationIDs.filter(id => id !== operation.id)
                  );
                  sendNeutralToast('Действие удалено');
                },
              }}
            />
          )),
        }}
      />
    </div>
  );
};
