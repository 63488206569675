import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type ProtocolFragment = { __typename: 'Protocol', id: string, name: string, forYoung: boolean, forCows: boolean, processKind: Types.ProcessKindEnum, deactivatedAt?: string | null };

export const ProtocolFragmentDoc = gql`
    fragment Protocol on Protocol {
  __typename
  id
  name
  forYoung
  forCows
  processKind
  deactivatedAt
}
    `;