import React from 'react';

export const Minus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6663 7.5C12.9425 7.5 13.1663 7.72386 13.1663 8C13.1663 8.27614 12.9425 8.5 12.6663 8.5C6.70574 8.5 9.65426 8.5 3.33301 8.5C3.05687 8.5 2.83301 8.27614 2.83301 8C2.83301 7.72386 3.05687 7.5 3.33301 7.5C9.03394 7.5 6.64049 7.5 12.6663 7.5Z"
      fill="currentColor"
    />
  </svg>
);
