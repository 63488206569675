import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { DefaultVeterinaryActivityFragmentDoc } from '../fragments/defaultVeterinaryActivity.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DefaultVeterinaryActivitiesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type DefaultVeterinaryActivitiesQuery = { __typename?: 'Query', defaultVeterinaryActivities: { __typename?: 'DefaultVeterinaryActivityConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'DefaultVeterinaryActivityEdge', cursor: string, node: { __typename: 'DefaultVeterinaryActivity', id: string, name: string, isDefault: boolean } }> } };


export const DefaultVeterinaryActivitiesDocument = gql`
    query defaultVeterinaryActivities($after: String, $first: Int, $search: String, $ids: [ID!]) {
  defaultVeterinaryActivities(
    after: $after
    first: $first
    search: $search
    ids: $ids
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...DefaultVeterinaryActivity
      }
    }
  }
}
    ${DefaultVeterinaryActivityFragmentDoc}`;

/**
 * __useDefaultVeterinaryActivitiesQuery__
 *
 * To run a query within a React component, call `useDefaultVeterinaryActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultVeterinaryActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultVeterinaryActivitiesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDefaultVeterinaryActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<DefaultVeterinaryActivitiesQuery, DefaultVeterinaryActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultVeterinaryActivitiesQuery, DefaultVeterinaryActivitiesQueryVariables>(DefaultVeterinaryActivitiesDocument, options);
      }
export function useDefaultVeterinaryActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultVeterinaryActivitiesQuery, DefaultVeterinaryActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultVeterinaryActivitiesQuery, DefaultVeterinaryActivitiesQueryVariables>(DefaultVeterinaryActivitiesDocument, options);
        }
export function useDefaultVeterinaryActivitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DefaultVeterinaryActivitiesQuery, DefaultVeterinaryActivitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DefaultVeterinaryActivitiesQuery, DefaultVeterinaryActivitiesQueryVariables>(DefaultVeterinaryActivitiesDocument, options);
        }
export type DefaultVeterinaryActivitiesQueryHookResult = ReturnType<typeof useDefaultVeterinaryActivitiesQuery>;
export type DefaultVeterinaryActivitiesLazyQueryHookResult = ReturnType<typeof useDefaultVeterinaryActivitiesLazyQuery>;
export type DefaultVeterinaryActivitiesSuspenseQueryHookResult = ReturnType<typeof useDefaultVeterinaryActivitiesSuspenseQuery>;
export type DefaultVeterinaryActivitiesQueryResult = Apollo.QueryResult<DefaultVeterinaryActivitiesQuery, DefaultVeterinaryActivitiesQueryVariables>;