import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { DefaultVeterinaryActivityFragmentDoc } from '../../../defaultVeterinaryActivities/gql/fragments/defaultVeterinaryActivity.graphql';
import { OperationFragmentDoc } from '../../../operations/gql/fragments/operation.graphql';
import { DiseaseFragmentDoc } from '../../../diseases/gql/fragments/disease.graphql';
import { InjectionFragmentDoc } from '../../../injections/gql/fragments/injection.graphql';
import { ProtocolFragmentDoc } from '../../../protocols/gql/fragments/protocol.graphql';
export type VeterinaryActivity_Protocol_Fragment = { __typename: 'Protocol', id: string, name: string, forYoung: boolean, forCows: boolean, processKind: Types.ProcessKindEnum, deactivatedAt?: string | null };

export type VeterinaryActivity_Injection_Fragment = { __typename: 'Injection', id: string, name: string, isDefault: boolean };

export type VeterinaryActivity_Disease_Fragment = { __typename: 'Disease', id: string, name: string, isDefault: boolean, shouldSpecifyLimb: boolean };

export type VeterinaryActivity_Operation_Fragment = { __typename: 'Operation', id: string, name: string, isDefault: boolean };

export type VeterinaryActivity_DefaultVeterinaryActivity_Fragment = { __typename: 'DefaultVeterinaryActivity', id: string, name: string, isDefault: boolean };

export type VeterinaryActivityFragment = VeterinaryActivity_Protocol_Fragment | VeterinaryActivity_Injection_Fragment | VeterinaryActivity_Disease_Fragment | VeterinaryActivity_Operation_Fragment | VeterinaryActivity_DefaultVeterinaryActivity_Fragment;

export const VeterinaryActivityFragmentDoc = gql`
    fragment VeterinaryActivity on VeterinaryActivity {
  __typename
  id
  name
  ...DefaultVeterinaryActivity
  ...Operation
  ...Disease
  ...Injection
  ...Protocol
}
    ${DefaultVeterinaryActivityFragmentDoc}
${OperationFragmentDoc}
${DiseaseFragmentDoc}
${InjectionFragmentDoc}
${ProtocolFragmentDoc}`;