import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { InjectionFragment } from '../gql/fragments/injection.graphql';
import {
  InjectionsQuery,
  InjectionsQueryVariables,
  useInjectionsQuery,
} from '../gql/queries/injections.graphql';

/**
 * Hook for using injections query with pagination
 */
export const useInjectionsPaginatedQuery = makeUsePaginatedQuery<
  InjectionFragment,
  InjectionsQuery,
  InjectionsQueryVariables
>({
  useQuery: useInjectionsQuery,
  getItemsFromQueryData: data => data.injections.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.injections.pageInfo,
});
