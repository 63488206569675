import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { ProtocolFragmentDoc } from '../../gql/fragments/protocol.graphql';
import { useProtocolsQuery } from '../../gql/queries/protocols.graphql';

/**
 * Select hook for a protocol
 */
export const useProtocolSelect = makeUseAsyncSelect({
  typeName: 'Protocol',
  fragment: ProtocolFragmentDoc,
  useQuery: useProtocolsQuery,
  getItemsFromQueryData: data => data.protocols.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.protocols.pageInfo,

  selectProps: {
    placeholder: 'Выберите протокол',
    getItemValue: item => item.id,
    getItemText: item => item?.name ?? '',
  },
});

/**
 * Select for a protocol
 */
export const ProtocolAsyncSelect =
  makeAsyncSelectFormComponent(useProtocolSelect);
