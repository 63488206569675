import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import R from 'ramda';

import { AsyncList, getAsyncListState } from '~/shared/components/AsyncList';
import { Button, ButtonProps } from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { Input, InputThemes, InputVariants } from '~/shared/components/Input';
import { SelectThemes } from '~/shared/components/Select';
import { TILES_DEFAULT_SKELETON_COUNT } from '~/shared/constants';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';
import { SerializableSearchParams } from '~/shared/types/serialization';

import {
  useLayoutState,
  WithSearchParamsValidation,
} from '~/services/navigation';

import {
  useEditReproductionCrReportModal,
  useReproductionCrReportsPaginatedQuery,
} from '~/entities/reproductionCrReports';
import { ReproductionCrReportFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrReport.graphql';
import { RoleAsyncSelect } from '~/entities/roles';

import { TileSizeSelector, useTileSize } from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { ReproductionCrReportTile } from './components';

interface AnalyticsReproductionCrPageSearchParams
  extends SerializableSearchParams {
  roleIDs: string[];
  search: string;
}

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/reproduction/cr'
)({
  component: AnalyticsReproductionCrPage,
  validateSearch: ({
    roleIDs,
    search,
  }: WithSearchParamsValidation<AnalyticsReproductionCrPageSearchParams>) => {
    return {
      roleIDs: roleIDs ?? [],
      search: search ?? '',
    };
  },
});

function AnalyticsReproductionCrPage() {
  const {
    search: debouncedSearch,
    setSearch: setDebouncedSearch,
    roleIDs,
    setRoleIDs,
  } = useSearchParamsState<AnalyticsReproductionCrPageSearchParams>();

  const { search, setSearch, isSearchActive } = useDebouncedSearch({
    defaultSearch: debouncedSearch,
    onDebouncedSearchChange: setDebouncedSearch,
  });

  const queryVariables = {
    roleIDs,
    search: debouncedSearch,
  };

  const { open: openEditReproductionCrReportModal } =
    useEditReproductionCrReportModal();

  const addButtonProps = {
    iconVariant: IconVariants.plus,
    children: 'Создать отчёт',
    onPress: () =>
      openEditReproductionCrReportModal({
        queryVariables,
      }),
  } satisfies ButtonProps;

  const { items: reproductionCrReportItems, ...asyncProps } =
    useReproductionCrReportsPaginatedQuery({
      variables: queryVariables,
    });

  const { tileSize, tilesGridClassName } = useTileSize(Route.fullPath);

  const filtersElement = (
    <div
      className={clsx(
        'flex items-end gap-16 mb-24',
        layoutStyles.limitedContainer
      )}
    >
      <RoleAsyncSelect
        {...{
          isMulti: true,
          label: 'Пользователь отчёта',
          className: 'default-control',
          name: 'roles',
          rawValue: roleIDs,
          theme: SelectThemes.light,
          onValueChange: newRoleIds => setRoleIDs(newRoleIds.map(R.prop('id'))),
          isUsedAsFilter: true,
        }}
      />
      <TileSizeSelector className="ml-a" pageName={Route.fullPath} />
      <Input
        {...{
          name: 'search',
          placeholder: 'Поиск по названию',
          value: search,
          onValueChange: setSearch,
          className: 'default-control',
          variant: InputVariants.search,
          theme: InputThemes.light,
        }}
      />
    </div>
  );

  const asyncListState = getAsyncListState({
    items: reproductionCrReportItems,
    skeletonItemsCount: TILES_DEFAULT_SKELETON_COUNT,
    isSearchActive: isSearchActive || !R.isEmpty(roleIDs),
    isLoading: asyncProps.isLoading,
  });

  useLayoutState({
    routerTabsRightContent: !asyncListState.isItemsNotCreated && (
      <Button key="addReport" {...addButtonProps} />
    ),
    dependencies: [
      asyncListState.isItemsNotCreated,
      reproductionCrReportItems.length,
    ],
  });

  return (
    <AsyncList<ReproductionCrReportFragment>
      {...{
        asyncListState,
        filtersElement,
        renderItemsWrapper: items => (
          <div
            className={clsx(
              layoutStyles.limitedContainer,
              'container-inline-size'
            )}
          >
            <div className={tilesGridClassName}>{items}</div>
          </div>
        ),
        renderNoItemsMessage: noItemsMessage => (
          <div
            className={clsx(
              layoutStyles.fillLeftoverHeightContainer,
              layoutStyles.limitedContainer,
              panelStyles.panel,
              'p-24 grid place-items-center'
            )}
          >
            {noItemsMessage}
          </div>
        ),
        noItemsMessage: (
          <DataBlockedMessage
            {...{
              isLarge: true,
              className: 'p-24',
              message: 'Отчёты по оплодотворяемости пока не созданы',
              buttonProps: addButtonProps,
            }}
          />
        ),
        noSearchItemsMessage: (
          <DataBlockedMessage
            {...{
              isLarge: true,
              className: 'p-24',
              message: 'Отчёты не найдены',
              description:
                'По вашему запросу нет подходящих отчётов. Используйте другое название',
            }}
          />
        ),
        renderItem: reproductionCrReport => (
          <ReproductionCrReportTile
            key={reproductionCrReport.id}
            {...{
              reproductionCrReport,
              queryVariables,
              size: tileSize,
            }}
          />
        ),
        ...asyncProps,
      }}
    />
  );
}
