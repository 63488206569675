import React from 'react';

import clsx from 'clsx';

import {
  SkeletonPlaceholder,
  TextSkeletonSizes,
} from '~/shared/components/Skeleton';
import {
  Table,
  TableColumnConfig,
  TableRowConfig,
} from '~/shared/components/Table';
import { formatInt, formatNumber } from '~/shared/helpers/number';
import { capitalize } from '~/shared/helpers/string';

import { DateFormats, formatDate } from '~/services/dateTime';

import { LivestockForecastMonthFragment } from '../../gql/fragments/livestockForecastMonth.graphql';
import { getMilkValue } from '../../helpers';

type CellData = LivestockForecastMonthFragment | SkeletonPlaceholder;

type ColumnConfig = TableColumnConfig<
  TableRowConfig<LivestockForecastMonthFragment, CellData>
>;

type RowConfig = TableRowConfig<LivestockForecastMonthFragment, CellData>;

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Data for the table
   */
  livestockForecastMonths: CellData[];
}

const NAME_COLUMN_WIDTH_PX = 132;
const MONTH_COLUMN_WIDTH_PX = 76;

export const LivestockForecastTable: React.FC<Props> = ({
  className,
  livestockForecastMonths,
}) => {
  const columnConfigs: ColumnConfig[] = [
    {
      title: 'Показатели',
      key: 'name',
      itemField: 'firstColumnContent',
      width: NAME_COLUMN_WIDTH_PX,
    },
    ...livestockForecastMonths.map<ColumnConfig>((month, index) => ({
      title: capitalize(
        formatDate(month?.forecastAt, DateFormats.onlyMonthShort)
      ),
      renderCellContent: item => item.renderCellContent?.(month),
      key: `${month?.forecastAt}__${index}`,
      width: MONTH_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
      cellTypographyProps: {
        skeletonSize: TextSkeletonSizes.small,
      },
    })),
  ];

  const rowConfigs: RowConfig[] = [
    {
      id: 'livestockCows',
      firstColumnContent: 'Коровы',
      renderCellContent: month => formatInt(month.livestockCows?.total),
      expandableRows: [
        {
          id: 'milkingCowsFirstLactation',
          firstColumnContent: 'Дойные первотёлки',
          renderCellContent: month =>
            formatInt(month.livestockCows?.firstLactation.milking),
        },
        {
          id: 'milkingCowsOtherLactations',
          firstColumnContent: 'Дойные, лактация ≥ 2',
          renderCellContent: month =>
            formatInt(month.livestockCows?.otherLactations.milking),
        },
        {
          id: 'milkingRate',
          firstColumnContent: 'Дойные, %',
          renderCellContent: month =>
            formatInt(month.livestockCows?.milkingRate),
        },
        {
          id: 'dryCowsFirstLactation',
          firstColumnContent: 'Сухост. первотёлки',
          renderCellContent: month =>
            formatInt(month.livestockCows?.firstLactation.dry),
        },
        {
          id: 'dryCowsOtherLactations',
          firstColumnContent: 'Сухост., лактация ≥ 2',
          renderCellContent: month =>
            formatInt(month.livestockCows?.otherLactations.dry),
        },
        {
          id: 'cowsFirstLactation',
          firstColumnContent: 'Первотёлки',
          renderCellContent: month =>
            formatInt(month.livestockCows?.firstLactation.total),
        },
        {
          id: 'cowsOtherLactations',
          firstColumnContent: 'Коровы, лактация ≥ 2 ',
          renderCellContent: month =>
            formatInt(month.livestockCows?.otherLactations.total),
        },
      ],
    },
    {
      id: 'livestockHeifers',
      firstColumnContent: 'Тёлки',
      renderCellContent: month => formatInt(month.livestockHeifers?.total),
      expandableRows: [
        {
          id: 'heifersZeroToTwoMonth',
          firstColumnContent: 'Тёлки 0-2 мес.',
          renderCellContent: month =>
            formatInt(month.livestockHeifers?.zeroToTwoMonth),
        },
        {
          id: 'heifersThreeToSixMonth',
          firstColumnContent: 'Тёлки 2-6 мес.',
          renderCellContent: month =>
            formatInt(month.livestockHeifers?.threeToSixMonth),
        },
        {
          id: 'heifersSevenToTwelveMonth',
          firstColumnContent: 'Тёлки 6-12 мес.',
          renderCellContent: month =>
            formatInt(month.livestockHeifers?.sevenToTwelveMonth),
        },
        {
          id: 'heifersTwelvePlusMonth',
          firstColumnContent: 'Тёлки 12+ мес.',
          renderCellContent: month =>
            formatInt(month.livestockHeifers?.twelvePlusMonth),
        },
      ],
    },
    {
      id: 'livestockBulls',
      firstColumnContent: 'Быки',
      renderCellContent: month => formatInt(month.livestockBulls?.total),
      expandableRows: [
        {
          id: 'bullsZeroToTwoMonth',
          firstColumnContent: 'Быки 0-2 мес.',
          renderCellContent: month =>
            formatInt(month.livestockBulls?.zeroToTwoMonth),
        },
        {
          id: 'bullsThreeToSixMonth',
          firstColumnContent: 'Быки 2-6 мес.',
          renderCellContent: month =>
            formatInt(month.livestockBulls?.threeToSixMonth),
        },
        {
          id: 'bullsSevenToTwelveMonth',
          firstColumnContent: 'Быки 6-12 мес.',
          renderCellContent: month =>
            formatInt(month.livestockBulls?.sevenToTwelveMonth),
        },
        {
          id: 'bullsTwelvePlusMonth',
          firstColumnContent: 'Быки 12+ мес.',
          renderCellContent: month =>
            formatInt(month.livestockBulls?.twelvePlusMonth),
        },
      ],
    },
    {
      id: 'livestockTotal',
      firstColumnContent: 'Поголовье',
      renderCellContent: month => formatInt(month?.livestockTotal),
    },
    {
      id: 'calvings',
      firstColumnContent: 'Ожидаемые отёлы',
      renderCellContent: month => formatInt(month.calvings?.total),
      expandableRows: [
        {
          id: 'calvingsHeifers',
          firstColumnContent: 'Нетели',
          renderCellContent: month => formatInt(month.calvings?.heifers),
        },
        {
          id: 'calvingsFirstLactation',
          firstColumnContent: 'Первотёлки',
          renderCellContent: month => formatInt(month.calvings?.firstLactation),
        },
        {
          id: 'calvingsOtherLactations',
          firstColumnContent: 'Коровы, лактация ≥ 2',
          renderCellContent: month =>
            formatInt(month.calvings?.otherLactations),
        },
        {
          id: 'calvingsNewbornHeifers',
          firstColumnContent: 'Ожидается тёлок',
          renderCellContent: month => formatInt(month.calvings?.newbornHeifers),
        },
        {
          id: 'calvingsNewbornBulls',
          firstColumnContent: 'Ожидается быков',
          renderCellContent: month => formatInt(month.calvings?.newbornBulls),
        },
      ],
    },
    {
      id: 'expectedDryTotal',
      firstColumnContent: 'Запуск в сухостой',
      renderCellContent: month => formatInt(month.expectedDry?.total),
      expandableRows: [
        {
          id: 'expectedDryFirstLactation',
          firstColumnContent: 'Первотёлки',
          renderCellContent: month =>
            formatInt(month.expectedDry?.firstLactation),
        },
        {
          id: 'expectedDryOtherLactations',
          firstColumnContent: 'Коровы, лактация ≥ 2',
          renderCellContent: month =>
            formatInt(month.expectedDry?.otherLactations),
        },
      ],
    },
    {
      id: 'expectedRetiredTotal',
      firstColumnContent: 'Ожидаемое выбытие',
      renderCellContent: month => formatInt(month.expectedRetired?.total),
    },
    {
      id: 'expectedRetiredCows',
      firstColumnContent: 'Ожид. выбытие коров',
      renderCellContent: month => formatInt(month.expectedRetired?.cows.total),
      expandableRows: [
        {
          id: 'expectedRetiredCowsFirstLactation',
          firstColumnContent: 'Первотёлки',
          renderCellContent: month =>
            formatInt(month.expectedRetired?.cows.firstLactation),
        },
        {
          id: 'expectedRetiredCowsOtherLactations',
          firstColumnContent: 'Коровы, лактация ≥ 2',
          renderCellContent: month =>
            formatInt(month.expectedRetired?.cows.otherLactations),
        },
      ],
    },
    {
      id: 'expectedRetiredHeifers',
      firstColumnContent: 'Ожид. выбытие тёлок',
      renderCellContent: month =>
        formatInt(month.expectedRetired?.heifers.total),
      expandableRows: [
        {
          id: 'expectedRetiredHeifersZeroToTwoMonth',
          firstColumnContent: 'Тёлки 0-2 мес.',
          renderCellContent: month =>
            formatInt(month.expectedRetired?.heifers.zeroToTwoMonth),
        },
        {
          id: 'expectedRetiredHeifersThreeToSixMonth',
          firstColumnContent: 'Тёлки 2-6 мес.',
          renderCellContent: month =>
            formatInt(month.expectedRetired?.heifers.threeToSixMonth),
        },
        {
          id: 'expectedRetiredHeifersSevenToTwelveMonth',
          firstColumnContent: 'Тёлки 6-12 мес.',
          renderCellContent: month =>
            formatInt(month.expectedRetired?.heifers.sevenToTwelveMonth),
        },
      ],
    },
    {
      id: 'expectedRetiredBulls',
      firstColumnContent: 'Ожид. выбытие быков',
      renderCellContent: month => formatInt(month.expectedRetired?.bulls.total),
      expandableRows: [
        {
          id: 'expectedRetiredBullsZeroToTwoMonth',
          firstColumnContent: 'Быки 0-2 мес.',
          renderCellContent: month =>
            formatInt(month.expectedRetired?.bulls.zeroToTwoMonth),
        },
        {
          id: 'expectedRetiredBullsThreeToSixMonth',
          firstColumnContent: 'Быки 2-6 мес.',
          renderCellContent: month =>
            formatInt(month.expectedRetired?.bulls.threeToSixMonth),
        },
        {
          id: 'expectedRetiredBullsSevenToTwelveMonth',
          firstColumnContent: 'Быки 6-12 мес.',
          renderCellContent: month =>
            formatInt(month.expectedRetired?.bulls.sevenToTwelveMonth),
        },
      ],
    },
    {
      id: 'milkPerHead',
      firstColumnContent: 'Надой на голову, кг',
      renderCellContent: month => formatInt(month?.milkPerHead),
    },
    {
      id: 'milkTotal',
      firstColumnContent: 'Вал молока, т',
      renderCellContent: month =>
        formatNumber(getMilkValue(month?.milk, 'total')),
      expandableRows: [
        {
          id: 'milkFirstLactation',
          firstColumnContent: 'Первотёлки',
          renderCellContent: month =>
            formatNumber(getMilkValue(month?.milk, 'firstLactation')),
        },
        {
          id: 'milkOtherLactations',
          firstColumnContent: 'Коровы, лактация ≥ 2',
          renderCellContent: month =>
            formatNumber(getMilkValue(month?.milk, 'otherLactations')),
        },
      ],
    },
  ];

  return (
    <Table<RowConfig, CellData>
      {...{
        className: clsx(className, 'min-w-full w-min'),
        items: rowConfigs,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
        hasExpandableRowContent: item => !!item.expandableRows,
        getExpandableRows: item => item.expandableRows,
      }}
    />
  );
};
