import { makeUseModal, ModalNames } from '~/services/modals';

import { AddCowByKeyModalProps } from './AddCowByKeyModal';
import { AddCowIdsToListModalProps } from './AddCowIdsToListModal';
import { AddDiseaseIdsToListModalProps } from './AddDiseaseIdsToListModal';
import { AddOperationIdsToListModalProps } from './AddOperationIdsToListModal';
import { MoveCowsModalProps } from './MoveCowsModal';
import { MoveCowsResultModalProps } from './MoveCowsResultModal';

export const useAddCowByKeyModal = makeUseModal<AddCowByKeyModalProps>(
  ModalNames.addCowByKeyModal
);

export const useMoveCowsModal = makeUseModal<MoveCowsModalProps>(
  ModalNames.moveCowsModal
);

export const useAddCowIdsToListModal = makeUseModal<AddCowIdsToListModalProps>(
  ModalNames.addCowIdsToListModal
);

export const useAddDiseaseIdsToListModal =
  makeUseModal<AddDiseaseIdsToListModalProps>(
    ModalNames.addDiseaseIdsToListModal
  );

export const useAddOperationIdsToListModal =
  makeUseModal<AddOperationIdsToListModalProps>(
    ModalNames.addOperationIdsToListModal
  );

export const useMoveCowsResultModal = makeUseModal<MoveCowsResultModalProps>(
  ModalNames.moveCowsResultModal
);
