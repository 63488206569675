import { Button, ButtonProps } from '~/shared/components/Button';
import { ContextMenu, ContextMenuProps } from '~/shared/components/ContextMenu';
import {
  FunctionButton,
  FunctionButtonProps,
} from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { MenuItemType } from '~/shared/components/Menu';
import { mergeProps } from '~/shared/helpers/mergeProps';
import { useElementSize } from '~/shared/hooks/useElementSize';

export interface ContextMenuButtonProps<
  ItemType extends MenuItemType = MenuItemType,
> extends Omit<ContextMenuProps<ItemType>, 'children'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Use FunctionButton as menu trigger, normal Button otherwise (default - true)
   */
  isFunctionButton?: boolean;
  /**
   * Props for FunctionButton, by default renders dots icon
   */
  functionButtonProps?: Omit<Partial<FunctionButtonProps>, 'ref'>;
  /**
   * Props for Button (when isFunctionButton === false), by default renders dots icon
   */
  buttonProps?: Omit<Partial<ButtonProps>, 'ref'>;
}

/**
 * Partial props can be used to extend ContextMenuButton via composition
 */
export type PartialContextMenuButtonProps<
  ItemType extends MenuItemType = MenuItemType,
> = Omit<Partial<ContextMenuButtonProps<ItemType>>, 'items'>;

export const ContextMenuButton = <
  ItemType extends MenuItemType = MenuItemType,
>({
  className,
  isDisabled = false,
  isFunctionButton = true,
  functionButtonProps,
  buttonProps,
  ...contextMenuProps
}: ContextMenuButtonProps<ItemType>) => {
  const [buttonSizeRef, { width: buttonWidth }] =
    useElementSize<HTMLButtonElement>();

  return (
    <ContextMenu
      {...mergeProps(
        {
          isDisabled,
          menuProps: { style: { minWidth: buttonWidth } },
        } satisfies Partial<ContextMenuProps<ItemType>>,
        contextMenuProps
      )}
    >
      {({ isOpen, setIsOpen, setIsTooltipOpen }) => {
        const commonButtonProps = {
          ref: buttonSizeRef,
          className,
          iconVariant: IconVariants.dots,
          isDisabled,
          isPressed: isOpen,
          onPress: () => {
            setIsTooltipOpen(false);

            setIsOpen(current => !current);
          },
          onKeyDown: e => {
            if (e.key === 'ArrowDown') {
              e.preventDefault();
              setIsOpen(true);
            }
          },
          iconProps: {
            'data-is-context-menu-open': isOpen,
          },
        } satisfies ButtonProps;

        return isFunctionButton ? (
          <FunctionButton
            {...mergeProps(commonButtonProps, functionButtonProps)}
          />
        ) : (
          <Button {...mergeProps(commonButtonProps, buttonProps)} />
        );
      }}
    </ContextMenu>
  );
};
