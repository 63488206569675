import React from 'react';

import R from 'ramda';

import { Icon, IconVariants } from '~/shared/components/Icon';
import { DEFAULT_PRECISION } from '~/shared/constants';
import { formatInt, formatNumber } from '~/shared/helpers/number';

import { SizeVariants } from '~/styles/__generated__/token-variants';

import { InputProps, InputSizes, InputVariants } from './types';

/**
 * Input variants, that should return number value type in onChange event
 */
export const NUMBER_INPUT_VARIANTS = [
  InputVariants.int,
  InputVariants.float,
  InputVariants.percent,
  InputVariants.money,
] satisfies InputVariants[];

const makeParseUserInput = (regExp: RegExp) => (value: string) =>
  (value.match(regExp) ?? []).join('');

const parseIntegerUserInput = makeParseUserInput(/(\d+)/g);

const parseFloatUserInput = makeParseUserInput(/[\d.,]+/g);

const formatRawToDisplayedFloatValue = (
  value: string,
  maximumFractionDigits: number = DEFAULT_PRECISION
) => {
  const floatUserInputParsed = parseFloatUserInput(value);

  const floatingPointDelimiterMatch = floatUserInputParsed.match(/[.,]/);
  // We should keep the original delimiter entered by user for rifm to work with the caret properly
  const originalDelimiter = floatingPointDelimiterMatch?.[0] ?? '.';

  const [intPart, fractionPart] = floatUserInputParsed.split(originalDelimiter);

  // Avoid rounding errors when user enters 1.239 with maximumFractionDigits === 2 we
  // must not to convert it to 1.24, it must stay 1.23
  const slicedFractionPart = !R.isNil(fractionPart)
    ? // Apply parse int, so we ensure, we don't have situation,
      // when 2 different delimiters were entered like ., and fractionPart is NaN after split
      parseIntegerUserInput(fractionPart).slice(0, maximumFractionDigits)
    : '';

  const parsedNumber = Number.parseFloat(`${intPart}.${slicedFractionPart}`);

  if (Number.isNaN(parsedNumber)) {
    return '';
  }

  const formattedValue = formatNumber(parsedNumber, {
    minimumFractionDigits: 0,
    maximumFractionDigits,
  });

  if (floatingPointDelimiterMatch) {
    const [formattedIntPart] = formattedValue.replace(',', '.').split('.');

    // We shouldn't allow to enter more zeroes, than we have fraction digits places - 1,
    // because, we don't want to have trailing zeroes in the fraction like 1.00,
    // but we should allow to input numbers like 1.05
    let formattedFractionPart = slicedFractionPart;
    if (
      slicedFractionPart &&
      slicedFractionPart[maximumFractionDigits - 1] === '0'
    ) {
      formattedFractionPart = slicedFractionPart.slice(0, -1);
    }

    return `${formattedIntPart}${originalDelimiter}${formattedFractionPart}`;
  }

  return formattedValue;
};

const formatDisplayedToRawFloatValue = (value: string) => {
  if (value === '') return null;
  return +parseFloatUserInput(value).replace(',', '.');
};

export const INPUT_DEFAULT_PROPS_BY_VARIANT_DICT: Partial<
  Record<InputVariants, Partial<InputProps>>
> = {
  [InputVariants.text]: {
    placeholder: 'Введите значение',
  },
  [InputVariants.search]: {
    placeholder: 'Поиск',
    addonBefore: ({ size = InputSizes.medium36 }) => (
      <Icon
        {...{
          variant: IconVariants.search,
          size:
            size === InputSizes.medium36
              ? SizeVariants.size24
              : SizeVariants.size16,
        }}
      />
    ),
  },
  [InputVariants.int]: {
    placeholder: 'Введите число',
    accept: /\d/g,
    formatRawToDisplayedValue: (value, { withFormat = true }) => {
      const parsedInt = parseIntegerUserInput(value);
      return withFormat ? formatInt(parsedInt) : parsedInt;
    },
    formatDisplayedToRawValue: value => {
      if (value === '') return null;
      return +parseIntegerUserInput(value);
    },
  },
  [InputVariants.float]: {
    placeholder: 'Введите число',
    accept: /[\d.,]/g,
    replace: value => value.replace('.', ','),
    formatRawToDisplayedValue: (value, { maximumFractionDigits }) =>
      formatRawToDisplayedFloatValue(value, maximumFractionDigits),
    formatDisplayedToRawValue: formatDisplayedToRawFloatValue,
    maximumFractionDigits: DEFAULT_PRECISION,
  },
  [InputVariants.percent]: {
    placeholder: 'Введите процент',
    accept: /[\d.,]/g,
    replace: value => value.replace('.', ','),
    formatRawToDisplayedValue: (value, { maximumFractionDigits }) => {
      const formattedNumber = formatRawToDisplayedFloatValue(
        value,
        maximumFractionDigits
      );
      return formattedNumber ? `${formattedNumber} %` : '';
    },
    formatDisplayedToRawValue: formatDisplayedToRawFloatValue,
    maximumFractionDigits: DEFAULT_PRECISION,
  },
  [InputVariants.money]: {
    placeholder: 'Введите сумму в рублях',
    accept: /[\d.,]/g,
    replace: value => value.replace('.', ','),
    formatRawToDisplayedValue: (value, { maximumFractionDigits }) => {
      const formattedNumber = formatRawToDisplayedFloatValue(
        value,
        maximumFractionDigits
      );
      return formattedNumber ? `${formattedNumber} ₽` : '';
    },
    formatDisplayedToRawValue: formatDisplayedToRawFloatValue,
    maximumFractionDigits: DEFAULT_PRECISION,
  },
};
