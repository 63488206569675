import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { DefaultVeterinaryActivityFragment } from '../gql/fragments/defaultVeterinaryActivity.graphql';
import {
  DefaultVeterinaryActivitiesQuery,
  DefaultVeterinaryActivitiesQueryVariables,
  useDefaultVeterinaryActivitiesQuery,
} from '../gql/queries/defaultVeterinaryActivities.graphql';

/**
 * Hook for using defaultVeterinaryActivities query with pagination
 */
export const useDefaultVeterinaryActivitiesPaginatedQuery =
  makeUsePaginatedQuery<
    DefaultVeterinaryActivityFragment,
    DefaultVeterinaryActivitiesQuery,
    DefaultVeterinaryActivitiesQueryVariables
  >({
    useQuery: useDefaultVeterinaryActivitiesQuery,
    getItemsFromQueryData: data =>
      data.defaultVeterinaryActivities.edges.map(R.prop('node')),
    getPageInfoFromQueryData: data => data.defaultVeterinaryActivities.pageInfo,
  });
